<template>
	<div
		style="width: 100%"
		:style="{
			backgroundColor: wstore.backgroundColor,
		}"
		class="l24-wrapper l24TraConterVod"
		:class="{ 'l24-mobile': wstore.mobile }"
	>
		<div style="margin-bottom: 20px">
			<div
				:style="{
					paddingTop: wstore.marginTop,
					paddingBottom: wstore.marginBottom,
					paddingLeft: wstore.marginLeft,
					paddingRight: wstore.marginRight,
					maxWidth: wstore.maxWidth,
				}"
				style="margin: 0 auto"
			>
				<div style="margin: 25px 0 25px 0" v-if="wstore.title">
					<strong
						class="ft16 vodCardTitle"
						:style="{ fontSize: wstore.titleFontSize, color: wstore.textColor }"
					>
						{{ wstore.title }}
					</strong>
				</div>
				<div class="cardViewWrap">
					<div class="wrap">
						<div
							class="grid"
							:style="{ gridGap: wstore.cardGap, gridTemplateColumns: `repeat(${wstore.colspan}, 1fr)` }"
						>
							<div class="" v-for="p in vods">
								<vodCard :p="p" :wstore="wstore" />
							</div>

							<div v-for="(pp, idx) in sklCnt" v-if="pageNo < 2">
								<div
									style="width: 100%; margin-bottom: 10px"
									:style="{
										aspectRatio: wstore.aspectRatio,
									}"
								>
									<UiSkl
										width="100%"
										height="100%"
										:borderRadius="wstore.borderRadius"
										style="margin-bottom: 10px"
									/>
								</div>

								<div>
									<UiSkl width="100%" height="20px" borderRadius="10px" style="margin-bottom: 10px" />
									<UiSkl width="30%" height="20px" borderRadius="10px" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="text-align: center; position: relative">
			<div v-if="!wstore.privew">
				<div id="ovTargetDiv" style="height: 200px; width: 100%; top: -150px; position: absolute"></div>
				<div style="display: flex; justify-content: center" v-if="!isEnd && loading">
					<div
						class="cardViewWrap"
						:style="{
							paddingTop: wstore.marginTop,
							paddingBottom: wstore.marginBottom,
							paddingLeft: wstore.marginLeft,
							paddingRight: wstore.marginRight,
							maxWidth: wstore.maxWidth,
						}"
					>
						<div class="wrap">
							<div
								class="grid"
								:style="{
									gridGap: wstore.cardGap,
									gridTemplateColumns: `repeat(${wstore.colspan}, 1fr)`,
								}"
							>
								<div v-for="i in wstore.colspan * 2">
									<vodCardSkl :wstore="wstore" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				style="
					opacity: 0.8;
					width: 100%;
					text-align: center;
					padding: 10px;
					background: url(https://tkmenfxu2702.edge.naverncp.com/profile/202311/9dcf0592abb1cb6cdaccb29dfe82c2f8.png);
					display: flex;
					justify-content: center;
					align-items: center;
				"
				v-if="wstore.privew"
			>
				<div style="padding: 2px; background: rgba(255, 255, 255, 0.24)">
					⚠️ 미리보기에서는 무한스크롤 기능이 동작하지 않습니다.
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps, onUnmounted } from "vue";
import vodCard from "./ui/vodCard.vue";
import vodCardSkl from "./ui/vodCardSkl.vue";

import { useL24Store } from "../store/l24";
import UiSkl from "./ui/skl.vue";

const store = useL24Store();
const props = defineProps({
	options: {
		type: Object,
	},
});

//data()
const wstore = ref({
	marginTop: "0",
	marginBottom: "0",
	marginLeft: "0",
	marginRight: "0",
	maxWidth: "980px",
	title: "",
	titleFontSize: "20px",
	cardGap: "10px",
	colspan: 4,
	orderCol: "no",
	privew: false,
	mobile: false,
});

const cacheTime = ref(10);
const isEnd = ref(false);
const init_load = ref(false);
const pageNo = ref(1);
const loading = ref(false);
const vods = ref([]);

//computed()
const config = computed(() => store.l24config || "");

const getPage = async (page) => {
	if (!page) {
		pageNo.value++;
	} else {
		pageNo.value = page;
	}

	await store
		.getVodCardList({
			row_count: wstore.value.limit,
			order_col: wstore.value.orderCol,
			page_no: pageNo.value,
			cache_time: cacheTime.value,
			no_product: true,
		})
		.then((res) => {
			try {
				if (!store.vods) {
					isEnd.value = true;
				}
				for (let i = 0; i < store.vods?.length; i++) {
					vods.value.push(store.vods[i]);
				}
			} catch (e) {}
		});
};

const vodTotal = computed(() => {
	try {
		return store.vods.length;
	} catch (e) {
		return 0;
	}
});
const sklCnt = computed(() => {
	try {
		let cnt = wstore.value.colspan - store.vods.length;
		if (cnt > 0) {
			return cnt;
		} else {
			return 0;
		}
	} catch (e) {
		return wstore.value.colspan;
	}
});

//methods()

const setOptions = async (options) => {
	let reload = false;
	try {
		if (options.limit) {
			if (options.limit != wstore.value.limit) {
				reload = true;
			}
		}
	} catch (e) {}

	wstore.value = { ...wstore.value, ...options };

	wstore.value.colspan = parseInt(wstore.value.colspan);
	wstore.value.limit = parseInt(wstore.value.limit);

	if (window.innerWidth < 768) {
		wstore.value.mobile = true;
		wstore.value.colspan = 2;
		wstore.value.maxWidth = window.innerWidth - 20 + "px";
		wstore.value.marginLeft = "0px";
		wstore.value.marginRight = "0px";
	} else {
		if (wstore.value.mobile) {
			wstore.value.colspan = 2;
			wstore.value.maxWidth = document.querySelector(".l24-wrapper").clientWidth - 20 + "px";
			wstore.value.marginLeft = "0px";
			wstore.value.marginRight = "0px";
		}
	}

	if (reload) {
		await store
			.getVodCardList({
				row_count: wstore.value.limit,
				order_col: wstore.value.orderCol,
				page_no: 1,
				cache_time: cacheTime.value,
				no_product: true,
			})
			.then((res) => {
				try {
					vods.value = [];

					for (let i = 0; i < store.vods?.length; i++) {
						vods.value.push(store.vods[i]);
					}
				} catch (e) {}
			});
	}
};

defineExpose({
	setOptions,
});

const ovCallBack = async () => {
	if (init_load.value && !isEnd.value && !loading.value) {
		console.log("!!ovCallBack");
		loading.value = true;
		await getPage();
		loading.value = false;
	}
};

onUnmounted(() => {
	window.removeEventListener("resize", () => {});
});

//mounted()
onMounted(async () => {
	//계정 환경설정 로드
	await store.getL24Config();

	wstore.value = { ...wstore.value, ...props.options };

	wstore.value.colspan = parseInt(wstore.value.colspan);
	wstore.value.limit = parseInt(wstore.value.limit);

	if (window.innerWidth < 768) {
		wstore.value.mobile = true;
		wstore.value.colspan = 2;
		wstore.value.maxWidth = window.innerWidth - 20 + "px";
		wstore.value.marginLeft = "0px";
		wstore.value.marginRight = "0px";
	} else {
		if (wstore.value.mobile) {
			wstore.value.colspan = 2;
			wstore.value.maxWidth = document.querySelector(".l24-wrapper").clientWidth - 20 + "px";
			wstore.value.marginLeft = "0px";
			wstore.value.marginRight = "0px";
		}
	}

	loading.value = true;

	await store
		.getVodCardList({
			row_count: wstore.value.limit,
			order_col: wstore.value.orderCol,
			page_no: 1,
			cache_time: cacheTime.value,
			no_product: true,
		})
		.then((res) => {
			try {
				vods.value = [];
				for (let i = 0; i < store.vods.length; i++) {
					vods.value.push(store.vods[i]);
				}

				try {
					const io = new IntersectionObserver(ovCallBack, { threshold: 0.7 });
					io.observe(document.getElementById("ovTargetDiv"));
				} catch (e) {}
			} catch (e) {}

			setTimeout(() => {
				loading.value = false;
				init_load.value = true;

				window.addEventListener("resize", () => {
					if (props.options.mobile) {
						wstore.value.mobile = true;
						wstore.value.colspan = 2;
						wstore.value.maxWidth = document.querySelector(".l24-wrapper").clientWidth - 20 + "px";
						wstore.value.marginLeft = "0px";
						wstore.value.marginRight = "0px";
					} else {
						if (window.innerWidth < 768) {
							wstore.value.mobile = true;
							wstore.value.colspan = 2;
							wstore.value.maxWidth = window.innerWidth - 20 + "px";
							wstore.value.marginLeft = "0px";
							wstore.value.marginRight = "0px";
						} else {
							wstore.value.mobile = false;
							wstore.value.colspan = props.options.colspan;
							wstore.value.maxWidth = props.options.maxWidth;
							wstore.value.marginLeft = props.options.marginLeft;
							wstore.value.marginRight = props.options.marginRight;
						}
					}
				});
			}, 800);
		});
});
</script>

<style scoped>
.l24TraConterVod {
	container-name: main-container;
	container-type: inline-size;
}

.l24 a {
	text-decoration: none;
	color: #000;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

*,
*:before,
*:after {
	box-sizing: border-box;
	font-family: "Noto Sans KR", sans-serif;
	line-height: 1.5;
	font-size: 14px;
}

.cardViewWrap {
	display: flex;
}

.row {
}

.wrap {
	width: 100%;
	height: 100%;
}

.grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 10px;
}

.l24-mobile .grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr) !important;
	grid-gap: 10px;
}

@container main-container  (max-width: 768px) {
	.grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr) !important;
		grid-gap: 10px;
	}
}

.grid-gap-1 {
	grid-gap: 10px;
}

.grid-gap-2 {
	grid-gap: 20px;
}

.grid-gap-3 {
	grid-gap: 30px;
}

.grid-gap-4 {
	grid-gap: 40px;
}

.grid-gap-5 {
	grid-gap: 50px;
}

.grid-gap-6 {
	grid-gap: 60px;
}

.grid-cols-1 {
	grid-template-columns: repeat(1, 1fr);
}
.grid-cols-2 {
	grid-template-columns: repeat(2, 1fr);
}
.grid-cols-3 {
	grid-template-columns: repeat(3, 1fr);
}
.grid-cols-4 {
	grid-template-columns: repeat(4, 1fr);
}
.grid-cols-5 {
	grid-template-columns: repeat(5, 1fr);
}
.grid-cols-6 {
	grid-template-columns: repeat(6, 1fr);
}
.grid-cols-7 {
	grid-template-columns: repeat(7, 1fr);
}
.grid-cols-8 {
	grid-template-columns: repeat(8, 1fr);
}
.grid-cols-9 {
	grid-template-columns: repeat(9, 1fr);
}
.grid-cols-10 {
	grid-template-columns: repeat(10, 1fr);
}
.pos_r {
	position: relative;
}
.pos_a {
	position: absolute;
}
</style>
