<script setup>
import {defineProps} from "vue";
import UiHls from "./hls.vue";

const props = defineProps({
	shorts: {
		type: Object,
	},
	wstore: {
		type: Object,
	},
});

const extRemove = (subject) => {
	subject = subject.replace(".mp4", "");
	subject = subject.replace(".MP4", "");
	subject = subject.replace(".mov", "");
	subject = subject.replace(".MOV", "");
	if (subject.indexOf("IMG_") > -1) {
		return "";
	}
	return subject;
};

const handleImageError = (event) => {
	let tsImage = event.target.getAttribute("productErrorImage");
	event.target.src = tsImage;
	event.target.style.aspectRatio = "1/1";
	event.target.style.objectFit = "cover";
};

const modifyContentURL = (url, duration) => {
	if (duration >= 8) {
		return url.replace(
			"/shorts/",
			`/vodStart/${3000}/vodEnd/${8000}/shorts/`,
		);
	} else {
		return url;
	}
};
</script>

<template>
	<div v-for="(s, idx) in shorts" class="boxItems">
		<div
			v-if="!s.isVideo"
			:style="{
				paddingBottom: wstore.cardGap,
			}"
			class="pb20"
		>
			<a
				:href="
					s.isProduct
						? `/product/detail/${s.isProduct.no}?ghost_mall_id=shorts24_inflow`
						: `/shorts24_player.html?content_no=${s.no}`
				"
			>
				<div style="background-color: #eaeaea;">
					<div :style="{background: `center/cover url('${s.isProduct.product_image_url}')`}"
					     style="background-color: #eaeaea; padding-bottom: 100%;">
						<!--					<img-->
						<!--						:src="s.isProduct.product_image_url"-->
						<!--						alt=""-->
						<!--						class="produtImage"-->
						<!--						style="background-color: #eaeaea;"-->
						<!--						loading="lazy"-->
						<!--						:productErrorImage="s.ui.thumb_url"-->
						<!--						@error="handleImageError"-->
						<!--					/>-->
					</div>
				</div>
				<div
					:style="{
						fontSize: wstore.mobile ? '14px' : wstore.titleFontSize,
					}"
					class="contentTitle"
				>
					<span v-html="s.isProduct.product_name"></span>
				</div>
			</a>
		</div>
		<div
			v-else
			:style="{
				paddingBottom: wstore.cardGap,
			}"
			class="pb20"
		>
			<a
				:href="
					s.isProduct
						? `/product/detail/${s.isProduct.no}?ghost_mall_id=shorts24_inflow`
						: `/shorts24_player.html?content_no=${s.no}`
				"
			>
				<div
					:style="{
						aspectRatio: s.ui.width > 0 && s.ui.width == s.ui.height ? `1/1` : `9/16`,
					}"
					class="showimages"
					style="background-color: #eaeaea; overflow: hidden"
				>
					<UiHls
						:id="`card_${s.no}`"
						:aspectRatio="s.ui.width > 0 && s.ui.width == s.ui.height ? `1/1` : `9/16`"
						:autoplay="true"
						:end="8000"
						:hlsUrl="modifyContentURL(s.ui.content_url, s.ui.duration)"
						:loop="true"
						:muted="true"
						:poster="s.ui.thumb_url"
						:start="3000"
						style="background-color: #eaeaea"
					/>
				</div>

				<div
					:style="{
						fontSize: wstore.mobile ? '14px' : wstore.titleFontSize,
					}"
					class="contentTitle"
				>
					<div v-if="s.isProduct && extRemove(s.ui.subject) != s.isProduct.product_name">
						<div style="padding-bottom: 5px" v-html="s.isProduct.product_name"></div>
						<div style="color: #9c9c9c; font-size: 0.9em" v-html="extRemove(s.ui.subject)"></div>
					</div>
					<div v-else>
						<div v-html="extRemove(s.ui.subject)"></div>
					</div>
				</div>
			</a>
		</div>
	</div>
</template>

<style scoped>
.pb20 {
	padding-bottom: 20px;
}

.produtImage {
	width: 100%;

	object-fit: cover;
	object-position: center;
}

.showimages {
	width: 100%;
	aspect-ratio: 9/16;
	max-height: 660px;
	object-fit: cover;
	object-position: center;
}

@container showcase-main-container  (max-width: 768px) {
	.showimages {
		max-height: 460px;
	}
}

.contentTitle {
	font-size: 14px;
	font-weight: 400;
	padding: 5px 0 5px 0;
	line-height: 1.5;
	max-width: 100%;
	overflow: hidden;
}

.boxItems a {
	color: #000;
	text-decoration: none;
}

.asp16-9 {
	aspect-ratio: 16/9;
}

.asp9-16 {
	aspect-ratio: 9/16;
}

.asp1-1 {
	aspect-ratio: 1/1;
}
</style>
