import {createApp} from "vue";
import DraggableResizableVue from "draggable-resizable-vue3";
import axios from "axios";

import {createPinia} from "pinia";

import cloudWidget from "../components/cloudWidget.vue";

import vodCardList from "../components/vodCardList.vue";
import vodCardListScroll from "../components/vodCardListScroll.vue";
import trailerInline from "../components/trailerInline.vue";
import vodInlineBig from "../components/vodInlineBig.vue";
import liveStateBig from "../components/liveStateBig.vue";
import imageLoader from "../components/imageLoader.vue";
import playerLoader from "../components/playerLoader.vue";
import livePopup from "../components/livePopup.vue";
import shortsList from "../components/shortsList.vue";
import floatingPlayerLoader from "../components/floatingPlayerLoader.vue";
import l24FloatingPlayerLoader from "../components/l24FloatingPlayerLoader.vue";
import showcase from "../components/showcase.vue";

window.l24Ws = [];

// pinia 각각 호출시 플롯 배너에서도 각각 호출하게 됨. -> 하나로 통일
const pinia = createPinia();

class l24Widget {
	constructor() {
	}

	/**
	 * 위젯 연동 세팅
	 * @param l24Id
	 * @param options
	 */
	async ready(l24Id, wl_no, user_no) {
		this.l24Id = l24Id;
		this.l24UserNo = user_no;

		window.l24Id = l24Id;
		window.l24UserNo = user_no;
		//기존 라이브 팝업 사용중지
		window.noLive24OldRun = true;

		window.addEventListener("message", (e) => {
			if (e.data.action == "emit") {
				if (e.data.fn == "top_reload") {
					location.reload();
				}
				if (e.data.fn == "l24_tmp_userid") {
					sessionStorage.setItem("L24_TMP_USERID", e.data.value);
					location.reload();
				}
				if (e.data.fn == "siteLogin") {
					try {
						//아임웹 대응
						SITE_MEMBER.openLogin();
					} catch (e) {
						if (window.l24LoginPageUrl) {
							//로그인페이지가 세팅되었다면?
							location.href = window.l24LoginPageUrl;
						} else {
							alert("LIVE24 : 로그인 연동이 필요합니다.");
						}
					}
				}
			}
		});

		//위젯 사용 보고
		try {
			if (wl_no == "[#WL_NO]") {
				wl_no = 2;
			}

			if (wl_no > 0) {
				let uuid = sessionStorage.getItem("L24W_UUID");
				if (!uuid) {
					uuid = this.generateRandomString(10);
					sessionStorage.setItem("L24W_UUID", uuid);
				}
				await this.setUseLog({
					wno: wl_no,
					uuid: uuid,
					url: location.href,
				});
			}
		} catch (e) {
			console.error(e);
		}
	}

	async setUseLog(params) {
		let url = `https://${this.l24Id}.live24.app/api/v4/WidgetLog/insert`;
		let res = await axios.post(url, {
			...params,
			mall_id: this.l24Id,
		});
		if (res.data.code == 200) {
			return res.data.result;
		}
	}

	generateRandomString(num) {
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
		let result = "";
		const charactersLength = characters.length;
		for (let i = 0; i < num; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}

		return result;
	}

	/**
	 * 유효성 검사
	 * @returns {boolean}
	 */
	validate() {
		if (!this.l24Id) {
			console.error(
				"l24Widget : l24Id is not defined , please call l24App.ready()",
			);
			return false;
		}

		return true;
	}

	async login(options) {
		window.l24LoginPageUrl = options.loginPageUrl;

		if (!options.userid) {
			return;
		}

		await this.site_user_sync({
			user_id: options.userid,
			user_nickname: options.userid,
		}).then(() => {
			console.log("LIVE24 : ", "로그인 실행되었습니다.");
		});
	}

	async site_user_sync(options) {
		let formData = new FormData();

		formData.append("user_id", options.user_id);
		formData.append("user_nickname", options.user_nickname);

		let res = await axios.post(
			`https://${this.l24Id}.live24.app/api/live24_cloud_api/user_sync`,
			formData,
		);

		if (res.data.code == 200) {
			try {
				window.user_key = res.data.result.user_key;
				window.user_no = res.data.result.user_no;
				window.user_id = options.user_id;
				try {
					sessionStorage.setItem("USER_KEY", user_key);
				} catch (e) {
				}
				return user_key;
			} catch (e) {
				try {
					sessionStorage.removeItem("USER_KEY", user_key);
				} catch (e) {
				}
			}
		}
	}

	/**
	 * 플로팅 라이브배너
	 * @param options
	 */
	cloudWidget(options, targetObjId) {
		if (!this.validate()) {
			return;
		}

		try {
			let body = document.getElementsByTagName("body")[0];
			if (targetObjId) {
				body = document.getElementById(targetObjId);
			}

			let chkdiv = document.getElementById("l24CloudWidget");

			console.log(!chkdiv);
			try {
				if (chkdiv != null) {
					body.removeChild(document.getElementById("l24CloudWidget"));
					let divTag = document.createElement("div");
					divTag.id = "l24CloudWidget";

					body.appendChild(divTag);
				} else {
					let divTag = document.createElement("div");
					divTag.id = "l24CloudWidget";

					body.appendChild(divTag);
				}
			} catch (e) {
				setTimeout(() => {
					let body = document.getElementsByTagName("body")[0];
					if (targetObjId) {
						body = document.getElementById(targetObjId);
					}
					body.removeChild(document.getElementById("l24CloudWidget"));
					let divTag = document.createElement("div");
					divTag.id = "l24CloudWidget";
					body.appendChild(divTag);
				}, 500);
			}
		} catch (e) {
			setTimeout(() => {
				let body = document.getElementsByTagName("body")[0];
				if (targetObjId) {
					body = document.getElementById(targetObjId);
				}
				let divTag = document.createElement("div");
				divTag.id = "l24CloudWidget";
				body.appendChild(divTag);
			}, 500);
		}

		// const pinia = createPinia()
		let vueApp = createApp(cloudWidget, {options: options})
			.use(pinia)
			.mount("#l24CloudWidget");
		return vueApp;
	}

	vodCardList(objid, options) {
		if (!this.validate()) {
			return;
		}

		try {
			if (document.querySelector(`#${objid}`)) {
				document.querySelector(`#${objid}`).innerHTML = "";
			}
		} catch (e) {
			console.log("LIVE24 Wigget: ", `${objid} is not defined`);
		}

		const pinias = createPinia();
		let vueApp = createApp(vodCardList, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}

	shortsList(objid, options) {
		if (!this.validate()) {
			return;
		}

		try {
			if (document.querySelector(`#${objid}`)) {
				document.querySelector(`#${objid}`).innerHTML = "";
			}
		} catch (e) {
			console.log("LIVE24 Wigget: ", `${objid} is not defined`);
		}

		const pinias = createPinia();
		let vueApp = createApp(shortsList, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}

	vodCardListScroll(objid, options) {
		if (!this.validate()) {
			return;
		}

		try {
			if (document.querySelector(`#${objid}`)) {
				document.querySelector(`#${objid}`).innerHTML = "";
			}
		} catch (e) {
			console.log("LIVE24 Wigget: ", `${objid} is not defined`);
		}

		const pinias = createPinia();
		let vueApp = createApp(vodCardListScroll, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}

	trailerInline(objid, options) {
		if (!this.validate()) {
			return;
		}

		try {
			if (document.querySelector(`#${objid}`)) {
				document.querySelector(`#${objid}`).innerHTML = "";
			}
		} catch (e) {
			console.log("LIVE24 Wigget: ", `${objid} is not defined`);
		}

		const pinias = createPinia();
		let vueApp = createApp(trailerInline, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}

	vodInlineBig(objid, options) {
		if (!this.validate()) {
			return;
		}

		try {
			if (document.querySelector(`#${objid}`)) {
				document.querySelector(`#${objid}`).innerHTML = "";
			}
		} catch (e) {
			console.log("LIVE24 Wigget: ", `${objid} is not defined`);
		}

		const pinias = createPinia();
		let vueApp = createApp(vodInlineBig, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}

	liveStateBig(objid, options) {
		if (!this.validate()) {
			return;
		}

		try {
			if (document.querySelector(`#${objid}`)) {
				document.querySelector(`#${objid}`).innerHTML = "";
			}
		} catch (e) {
			console.log("LIVE24 Wigget: ", `${objid} is not defined`);
		}

		const pinias = createPinia();
		let vueApp = createApp(liveStateBig, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}

	playerLoader(options, targetObjId) {
		let objid = "l24PlayerLoader";

		try {
			if (options.targetDiv) {
				targetObjId = options.targetDiv;
			}
		} catch (e) {
		}

		if (targetObjId) {
			objid = targetObjId;
			options.innerMode = true;
		} else {
			let newDiv = document.createElement("div");
			newDiv.setAttribute("id", objid);
			if (options.privew) {
				newDiv.style.position = "absolute";
				newDiv.style.top = "0px";
				newDiv.style.left = "0px";
			} else {
				newDiv.style.position = "fixed";
				newDiv.style.zIndex = "999999";
			}
			newDiv.style.bottom = "0px";
			newDiv.style.right = "0px";
			document.body.appendChild(newDiv);
		}

		const pinias = createPinia();
		let vueApp = createApp(playerLoader, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}

	shorts24Player(targetObjId) {
		let objid = "l24PlayerLoader";

		let options = {
			loginType: "cafe24",
			type: "shorts",
			ui: "mobile",
			s24_in_player: "Y",
			targetDiv: targetObjId ? targetObjId : null,
		};

		if (targetObjId) {
			objid = targetObjId;
			options.innerMode = true;
		} else {
			let newDiv = document.createElement("div");
			newDiv.setAttribute("id", objid);
			if (options.privew) {
				newDiv.style.position = "absolute";
				newDiv.style.top = "0px";
				newDiv.style.left = "0px";
			} else {
				newDiv.style.position = "fixed";
				newDiv.style.zIndex = "999999";
			}
			newDiv.style.bottom = "0px";
			newDiv.style.right = "0px";
			document.body.appendChild(newDiv);
		}

		const pinias = createPinia();
		let vueApp = createApp(playerLoader, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}

	floatingPlayerLoader(options) {
		let objid = "l24PlayerLoaderFloating";

		try {
			document.querySelector(`#${objid}`).remove();
		} catch (e) {
		}

		let newDiv = document.createElement("div");
		newDiv.setAttribute("id", objid);
		newDiv.style.class = "l24PlayerLoaderFloating";
		newDiv.style.position = "fixed";
		newDiv.style.zIndex = "999999";
		newDiv.style.bottom = "0px";
		newDiv.style.right = "0px";

		document.body.appendChild(newDiv);

		const pinias = createPinia();

		let vueApp = createApp(floatingPlayerLoader, {options: options})
			.use(pinias)
			.use(DraggableResizableVue)
			.mount("#" + objid);

		return vueApp;
	}

	l24FloatingPlayerLoader(options) {
		let objid = "l24PlayerLoaderliveFloating";

		try {
			document.querySelector(`#${objid}`).remove();
		} catch (e) {
		}

		let newDiv = document.createElement("div");
		newDiv.setAttribute("id", objid);
		newDiv.style.class = "l24PlayerLoaderliveFloating";
		newDiv.style.position = "fixed";
		newDiv.style.zIndex = "999999";
		newDiv.style.bottom = "0px";
		newDiv.style.right = "0px";

		document.body.appendChild(newDiv);

		const pinias = createPinia();

		let vueApp = createApp(l24FloatingPlayerLoader, {options: options})
			.use(pinias)
			.use(DraggableResizableVue)
			.mount("#" + objid);

		return vueApp;
	}

	livePopup(targetObjId, options) {
		let objid = "l24PopupLoader";
		let zIndex = 99999;
		if (targetObjId) {
			objid = targetObjId;
			options.innerMode = true;
		} else {
			let newDiv = document.createElement("div");
			newDiv.setAttribute("id", objid);
			if (options.privew) {
				newDiv.style.position = "absolute";
				newDiv.style.top = "0px";
				newDiv.style.left = "0px";
			} else {
				newDiv.style.position = "fixed";
			}
			newDiv.style.bottom = "0px";
			newDiv.style.right = "0px";

			if (options.zIndex) {
				zIndex = options.zIndex;
				newDiv.style.zIndex = zIndex;
			}
			document.body.appendChild(newDiv);
		}

		const pinias = createPinia();
		let vueApp = createApp(livePopup, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}

	imageLoader(objid, options) {
		if (!this.validate()) {
			return;
		}

		try {
			if (document.querySelector(`#${objid}`)) {
				document.querySelector(`#${objid}`).innerHTML = "";
			}
		} catch (e) {
			console.log("LIVE24 Wigget: ", `${objid} is not defined`);
		}

		const pinias = createPinia();
		let vueApp = createApp(imageLoader, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}

	showcase(objid, options) {
		if (!this.validate()) {
			return;
		}

		try {
			if (document.querySelector(`#${objid}`)) {
				document.querySelector(`#${objid}`).innerHTML = "";
			}
		} catch (e) {
			//console.log("LIVE24 Wigget: ", `${objid} is not defined`);
		}

		const pinias = createPinia();
		let vueApp = createApp(showcase, {options: options})
			.use(pinias)
			.mount("#" + objid);

		return vueApp;
	}
}

export default l24Widget;
