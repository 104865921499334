<template>
	<div class="l24TraConter">
		<div
			v-if="init_load"
			class="w-full"
			:class="{ 'l24-mobile': wstore.mobile }"
			:style="{
				'--backgroundColor': backgroundColor,
				'--textColor': textColor,
			}"
		>
			<div
				class="l24LSarea"
				:style="{
					paddingTop: wstore.marginTop,
					paddingBottom: wstore.marginBottom,
					paddingLeft: wstore.marginLeft,
					paddingRight: wstore.marginRight,
				}"
				:class="liveState"
			>
				<div
					:class="
						wstore.order != 'onlyPlay'
							? 'grid grid-cols-2 contentWarp h-full'
							: 'h-full w-full flex items-center justify-center'
					"
				>
					<div
						v-if="wstore.order != 'onlyPlay'"
						class="h-full text-center infoFrame"
						:style="{ order: wstore.order == '21' ? 2 : 1 }"
					>
						<div class="flex h-full w-full items-center justify-center">
							<div class="mt-5 mb-5">
								<div class="pt-4 pb-2">
									<img :src="store.l24config.profile_image_url" alt="" class="shopProfile" />
								</div>

								<div class="p-2">
									<strong :style="{ color: textColor }" class="bigText">{{
										store.l24config.nickname
									}}</strong>
								</div>

								<div class="pt-4">
									<div class="p-2" v-if="liveState">
										<strong :style="{ color: textColor }" class="bigText">{{
											liveUi.subject
										}}</strong>
									</div>
									<div class="p-2" v-else>
										<strong :style="{ color: textColor }" class="bigText"
											>라이브 제목이 표시됨</strong
										>
									</div>

									<div class="p-2" v-if="liveState == 'vod' || liveState == 'trailer'">
										<strong :style="{ color: textColor }" class="bigTextLg">{{
											ToDateFormat(store.liveState.ui.startTime)
										}}</strong>
									</div>
									<div class="p-2" v-else-if="liveState == 'live'">
										<strong :style="{ color: textColor }" class="bigTextLg">{{
											ToDateFormat(store.liveState.ui.startTime)
										}}</strong>
									</div>
									<div class="p-2" v-else>
										<strong :style="{ color: textColor }" class="bigTextLg">0000.00.00</strong>
									</div>

									<div
										class="p-2"
										v-if="liveState == 'live' || liveState == 'vod' || liveState == 'trailer'"
									>
										<button
											type="button"
											class="btn btn-dark btn-rounded"
											style="
												background-color: #000;
												color: #f3f3f3;
												cursor: pointer;
												font-size: 16px;
											"
											@click="go_btn"
											v-if="liveState == 'live'"
										>
											라이브 참여하기
										</button>
										<button
											type="button"
											class="btn btn-dark btn-rounded ft16"
											style="
												background-color: #000;
												color: #f3f3f3;
												cursor: pointer;
												font-size: 16px;
											"
											@click="go_btn"
											v-else-if="liveState == 'vod'"
										>
											라이브 다시보기
										</button>
										<button
											type="button"
											class="btn btn-dark btn-rounded"
											style="
												background-color: #000;
												color: #f3f3f3;
												cursor: pointer;
												font-size: 16px;
											"
											@click="go_btn"
											v-else
										>
											라이브 예정
										</button>
									</div>
									<div class="p-2" v-else>
										<button
											type="button"
											class="btn btn-dark btn-rounded"
											style="background-color: #000"
										>
											<span style="color: white">바로가기</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="pt-3 pb-3 h-full"
						:style="{
							order: wstore.order == '21' ? 1 : 2,
							maxWidth: wstore.order != 'onlyPlay' ? '' : '980px',
							width: wstore.order != 'onlyPlay' ? '' : '460px',
						}"
						style="display: flex; align-items: center"
					>
						<div
							class="pos_r player_frame w-full h-full"
							:style="{
								aspectRatio: wstore.aspectRatio,
								borderRadius: props.options.order != 'onlyPlay' ? '' : '0',
							}"
						>
							<div
								v-if="liveState"
								style="height: 100%"
								:style="{ width: wstore.aspectRatio == '1/1' ? '435px' : '100%' }"
							>
								<div
									class="noneClick"
									@click="go_btn()"
									v-if="liveState == 'live' || liveState == 'vod'"
									style="width: 100%; height: 100%"
								></div>

								<iframe
									:src="ifrUrl ? ifrUrl + '&nox=1&muted=1' : liveUi.link_url + '&nox=1&muted=1'"
									frameborder="0"
									width="100%"
									height="100%"
									scrolling="no"
								></iframe>
							</div>
							<div v-else class="h-full flex justify-center items-center text-white">
								<div class="text-center">
									<div style="font-size: 30px">⚠️</div>
									<div style="color: white">표시할 내용이 없습니다.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="flex justify-center items-center">
			<div
				class="w-full"
				:class="{ 'l24-mobile': wstore.mobile }"
				:style="{
					'--backgroundColor': '#c4c4c4',
					'--textColor': textColor,
				}"
			>
				<div class="l24LSarea">
					<div class="grid grid-cols-2 contentWarp h-full">
						<div class="flex justify-center items-center" :style="{ order: wstore.order == '21' ? 2 : 1 }">
							<div>
								<div>
									<UiSkl width="80px" height="80px" borderRadius="500px" style="margin: 20px auto" />
								</div>
								<div>
									<UiSkl width="120px" height="20px" style="margin: 20px auto" />
								</div>

								<div style="margin-top: 30px">
									<UiSkl width="300px" height="30px" style="margin: 20px auto" />
								</div>

								<div style="margin-top: 20px">
									<UiSkl width="200px" height="40px" style="margin: 20px auto" />
								</div>

								<div style="margin-top: 40px">
									<UiSkl width="150px" height="40px" borderRadius="30px" style="margin: 20px auto" />
								</div>
							</div>
						</div>
						<div :style="{ order: wstore.order == '21' ? 1 : 2 }">
							<div class="player_frame" style="background-color: #d5d5d5">
								<UiSkl width="100%" height="100%" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps } from "vue";

import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

import UiSkl from "../components/ui/skl.vue";
import { useL24Store } from "../store/l24";
const store = useL24Store();

const props = defineProps({
	options: {
		type: Object,
	},
});

//data()
const wstore = ref({
	marginTop: "0",
	marginBottom: "0",
	marginLeft: "0",
	marginRight: "0",
	maxWidth: "980px",
	backgroundColor: "#B90922",
	textColor: "#f3f3f3",
	mobile: false,
	livePlayerLink: "",
});
let init_load = ref(false);

let backgroundColor = computed(() => {
	if (wstore.value.backgroundColor) {
		return wstore.value.backgroundColor;
	} else {
		return "#B90922";
	}
});
let textColor = computed(() => {
	if (wstore.value.textColor) {
		return wstore.value.textColor;
	} else {
		return "#f3f3f3";
	}
});

let zoom = computed(() => {
	if (wstore.value.zoom) {
		return wstore.value.zoom;
	} else {
		return 1;
	}
});

const ToDateFormat = (val, fm) => dayjs(val).format(fm ? fm : "YYYY.MM.DD");
const ToTimeFormat = (val, fm) => dayjs(val).format(fm ? fm : "HH:mm");

const startDate = computed(() => {
	if (store.liveState) {
		return dayjs(store.liveState.ui.startTime).format("YYYY.MM.DD HH");
	} else {
		return "";
	}
});

const liveState = computed(() => {
	try {
		return store.liveState.state || "";
	} catch (e) {
		return "";
	}
});

const ifrUrl = ref("");

const liveUi = computed(() => {
	try {
		if (store.liveState.ui.link_url.indexOf("zoom=") > -1) {
			store.liveState.ui.link_url = store.liveState.ui.link_url.split("zoom=")[0] + "zoom=" + zoom.value;
		} else {
			if (store.liveState.ui.link_url.indexOf("?") > -1) {
				store.liveState.ui.link_url = store.liveState.ui.link_url + "&muted=1&zoom=" + zoom.value;
			} else {
				store.liveState.ui.link_url = store.liveState.ui.link_url + "?muted=1&zoom=" + zoom.value;
			}
		}

		store.liveState.ui.link_url = store.liveState.ui.link_url;

		let newPlayerUrl = `https://player-v4.live24.app/player/${store.l24config.user_no}?${
			store.liveState.ui.link_url.split("?")[1]
		}`;
		store.liveState.ui.ifrUrl = newPlayerUrl;
		ifrUrl.value = newPlayerUrl;
		return store.liveState.ui || [];
	} catch (e) {
		return [];
	}
});

const go_btn = () => {
	if (store.liveState.state == "live") {
		if (wstore.value.livePlayerLink && wstore.value.livePlayerLink != "/live24_player.html") {
			location.href = wstore.value.livePlayerLink;
		} else {
			location.href = store.liveState.ui.link_url;
		}
	} else if (store.liveState.state == "vod") {
		window.open(store.liveState.ui.link_url, "vod", "width=380,height=700");
	}
};

//methods()
const run = () => {
	init_load.value = true;
};

const setOptions = async (options) => {
	let reload = false;
	try {
		if (options.state) {
			if (options.state != wstore.value.state) {
				reload = true;
			}
		}
	} catch (e) {}
	wstore.value = { ...wstore.value, ...options };

	if (reload) {
		await store.getLiveState({
			state: wstore.value.state,
		});
	}
};
defineExpose({
	setOptions,
});

//mounted()
onMounted(async () => {
	//계정 환경설정 로드
	let l24config = await store.getL24Config();

	wstore.value = { ...wstore.value, ...props.options };

	await store.getLiveState({
		state: wstore.value.state,
	});

	run();
});
</script>

<style scoped>
.l24TraConter {
	container-name: main-container;
	container-type: inline-size;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

*,
*:before,
*:after {
	box-sizing: border-box;
	font-family: "Noto Sans KR", sans-serif;
	line-height: 1.5;
	font-size: 14px;
}
.pos_r {
	position: relative;
}
.w-full {
	width: 100%;
}

.h-full {
	height: 100%;
}

.flex {
	display: flex;
}

.items-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.text-center {
	text-align: center;
}
.btn {
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}
.btn-dark {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
}
.btn-rounded {
	border-radius: 60px;
	padding: 7px 18px;
}

.l24LSarea {
	background-color: var(--backgroundColor);
	color: var(--textColor);
	min-height: 600px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.contentWarp {
	width: 100%;
	max-width: 980px;
	margin: auto;
}

.shopProfile {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	object-fit: cover;
}

.bigText {
	font-size: 20px;
}
.bigTextLg {
	font-size: 26px;
}

.noneClick {
	cursor: pointer;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.productItems {
	width: 100%;
	aspect-ratio: 1/1;
	object-fit: cover;
	border-radius: 10px;
}

.Trawrap {
	max-width: 1600px;
	margin: auto;
}

.backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 2;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.leftWrap {
	position: relative;
}

.rightWrap {
	padding: 0 20px;
}

.vodr_box {
	background: red;
	color: #fff;
	font-size: 18px;
	letter-spacing: 0;
	font-weight: 600;
	margin-right: 5px;
	display: inline-block;
	line-height: 1.3;
	border-radius: 20px;
	padding: 0.5px 8px 0 8px;
}

.liveTitle {
	font-size: 24px;
	font-weight: 800;
	margin-bottom: 0.1rem;
	letter-spacing: -0.1rem;
	font-family: Noto Sans KR;
	color: #000;
}

.smalltitle {
	letter-spacing: -0.1rem;
	line-height: 1;
	color: #333;
	font-size: 19px;
}

.el-text2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	max-height: 44px;

	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.profile_img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}

@container main-container (min-width: 728px) {
	.infoFrame {
		height: 620px;
	}
	.player_frame {
		width: 100%;
		aspect-ratio: 9/12;
		max-height: 95%;
		max-width: 95%;

		background-color: #000;
		border-radius: 5px 5px 40px 5px;
		overflow: hidden;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

@container main-container (max-width: 728px) {
	.player_frame {
		width: calc(100% - 20px);
		aspect-ratio: 9/12;
		max-height: 95%;
		background-color: #000;
		border-radius: 5px 5px 40px 5px;
		overflow: hidden;
		margin: 10px;
	}

	.infoFrame {
		height: 400px;
	}
}

.l24-mobile .infoFrame {
	height: 400px;
}

.l24-mobile .player_frame {
	width: calc(100% - 20px);
	aspect-ratio: 9/12;
	max-height: 95%;
	background-color: #000;
	border-radius: 5px 5px 40px 5px;
	overflow: hidden;
	margin: 10px;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
}

@container main-container (max-width: 768px) {
	.grid {
		display: grid;
		grid-template-columns: repeat(1, 1fr) !important;
		grid-gap: 10px;
	}
}

.l24-mobile .grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr) !important;
	grid-gap: 10px;
}
</style>
