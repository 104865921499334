<template>
	<Transition name="fade">
		<a
			href="javascript:void(0)"
			@click="productOpen(p)"
			:style="{
				color: wstore.textColor,
			}"
		>
			<span class="flex">
				<div>
					<img
						:src="p.product_image_url"
						class="bg-light"
						style="object-fit: cover; width: 78px; height: 78px; border-radius: 5px"
						alt=""
					/>
				</div>
				<div class="flex1" style="padding-left: 1em">
					<div class="el-text2" style="margin-bottom: 0.5em">
						<strong class="product-name" :style="{ color:  wstore.textColor }">{{ p.product_name }}</strong>
					</div>

					<div>
						<strong class="r_box" style="zoom: 0.7" v-if="p.retail_price > 0 && p.retail_price !== p.price">
							{{ Math.abs(Math.round((p.price / p.retail_price) * 100 - 100)) }}%
						</strong>

						<!--						<span>
							<del class="text-muted"
								>{{ p.retail_price ? parseInt(p.retail_price).toLocaleString() : 0 }}원</del
							>
							<i class="ph-arrow-right"></i>
						</span>-->

						<strong class="price" :style="{ color:  wstore.textColor }">{{ p.price ? parseInt(p.price).toLocaleString() : 0 }}원</strong>
					</div>
				</div>
			</span>
		</a>
	</Transition>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
defineProps({
	p: {
		type: Object,
		required: true,
	},
	wstore: {
		type: Object,
	},
});

const productOpen = (data) => {
	window.open(data.product_link);
};
</script>

<style scoped>
.cardWrap {
	width: 100%;
	height: 100%;
}

.product-name {
	font-size: 17px;
	padding: 0 0 0 0;

	letter-spacing: -0.04px;
	font-weight: 400;
	line-height: 1.3;
	margin-bottom: 13px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	max-height: 50px;
	transition: all 0.2s ease;
	font-width: 400;
}
.text-muted {
	opacity: 0.7;
}
.r_box {
	background: #ff0000;
	border-radius: 0.4rem;
	color: #fff;
	padding: 0rem 0.3rem 0rem;
	font-size: 23px;
	letter-spacing: 0;
	font-weight: 600;
	margin-right: 5px;
	display: inline-block;
	line-height: 1.3;
}
.vodr_box {
	background: #ff0000;
	border-radius: 20px;
	color: #fff;
	padding: 0.5px 8px 0 8px;
	font-size: 23px;
	letter-spacing: 0;
	font-weight: 600;
	margin-right: 5px;
	display: inline-block;
	line-height: 1.3;
}
.price {
	font-size: 18px;
	font-weight: 600;
	font-family: "Noto Sans KR";
}
.el-text2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	max-height: 44px;

	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.flex {
	display: flex;
}

.items-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.flex1 {
	flex: 1;
}
.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}
</style>
