<script setup>
import "animate.css";
import { computed, defineProps, onMounted, onUnmounted, ref, watch, defineComponent } from "vue";

import { useL24Store } from "../store/l24";
import UAParser from "ua-parser-js";
import axios from "axios";
import UiHls from "./ui/hls.vue";

const store = useL24Store();

const props = defineProps({
	options: {
		type: Object,
	},
});

const element = ref({
	x: -200,
	y: -320,
	width: 200,
	height: 360,
	isActive: false,
});

const toLoad = ref(false);
const initLoad = ref(false);
const isMobile = ref(false);
const isPlay = ref(true);
const isMuted = ref(true);
const isHls = ref(true);
const isVideoReady = ref(false);

const wstore = ref({
	privew: false,
	innerMode: false,
	contentNo: null,
	userNo: null,
	productNo: null,
});

const setOptions = (options) => {
	initLoad.value = false;
	wstore.value = { ...wstore.value, ...options };
};

defineExpose({
	setOptions,
});

let l24config = null;
let shorts = ref(null);
const getShortsInfo = async () => {
	if (wstore.value.productNo && wstore.value.userNo) {
		const res = await axios.get(`https://live24.app/api/cafe24_floating/product`, {
			params: {
				product_no: wstore.value.productNo,
				user_no: wstore.value.userNo,
				top_url: location.href,
				is_mobile: isMobile.value,
				user_agent: navigator.userAgent,
			},
		});
		if (res.data.code == 200) {
			return res.data.result;
		}
	}
};

const goPlayer = () => {
	location.href = shorts.value.shorts_url;
};

let video = null;
const activeBtn = ref(false);

let isVetical = ref(true);
let activeTimer = null;
let outActiveTimer = null;
let fadeAni = ref("animate__fadeIn");

const inActive = () => {
	try {
		isPlay.value = document.getElementById("l24werPlayer_player_s24lod").paused;
	} catch (e) {}
	if (!activeBtn.value) {
		fadeAni.value = "animate__fadeIn";
		activeBtn.value = true;
		clearTimeout(activeTimer);
		activeTimer = setTimeout(() => {
			outActive();
		}, 3000);
	}
};
const outActive = () => {
	fadeAni.value = "animate__fadeOut";
	clearTimeout(outActiveTimer);
	outActiveTimer = setTimeout(() => {
		activeBtn.value = false;
	}, 200);
};

const setVideoSize = () => {
	if (shorts.value.width > 0 && shorts.value.height > 0) {
		if (shorts.value.width > shorts.value.height) {
			isVetical.value = false;

			if (window.innerWidth < 600) {
				element.value = {
					x: -220,
					y: -100,
					width: 220,
					height: 130,
					isActive: false,
				};
			} else {
				element.value = {
					x: -280,
					y: -160,
					width: 290,
					height: 170,
					isActive: false,
				};
			}
		} else if (shorts.value.width == shorts.value.height) {
			if (window.innerWidth < 600) {
				element.value = {
					x: -180,
					y: -180,
					width: 180,
					height: 180,
					isActive: false,
				};
			} else {
				element.value = {
					x: -210,
					y: -210,
					width: 220,
					height: 220,
					isActive: false,
				};
			}
		}
	}
};

let initRunTs = false;
let initRunTsTimer = null;
let initRunWh = false;
const initRun = () => {
	if (initRunTs) {
		return;
	}
	initRunWh = true;
	try {
		video = document.getElementById("l24werPlayer_player_s24lod");
		try {
			if (shorts.value.width != video.videoWidth && video.videoWidth > 0) {
				shorts.value.width = video.videoWidth;
			}
			if (shorts.value.height != video.videoHeight && video.videoHeight > 0) {
				shorts.value.height = video.videoHeight;
			}

			setVideoSize();

			if (video.videoWidth > 0 && video.videoHeight > 0) {
			} else {
				initRunTsTimer = setTimeout(() => {
					initRun();
				}, 200);
				return;
			}
		} catch (e) {}
		video.removeEventListener("play", () => {});
		video.removeEventListener("pause", () => {});
		video.removeEventListener("volumechange", () => {});

		video.addEventListener("play", () => {
			isPlay.value = true;
		});
		video.addEventListener("pause", () => {
			isPlay.value = false;
		});
		video.addEventListener("volumechange", () => {
			if (video.muted) {
				isMuted.value = true;
			} else {
				isMuted.value = false;
			}
		});

		toLoad.value = false;
		setVideoSize();

		if (initRunWh && initRunWh) {
			initRunTs = true;
			setTimeout(() => {
				toLoad.value = true;
				isVideoReady.value = true;
			}, 200);
		} else {
			initRunTsTimer = setTimeout(() => {
				initRun();
			}, 200);
		}
	} catch (e) {
		initRunTsTimer = setTimeout(() => {
			initRun();
		}, 200);
	}
};

onMounted(async () => {
	if (window.l24idtoLoaTs) return;

	console.log("run l24 floating to live");

	if (window.innerWidth < 600) {
		element.value = {
			x: -160,
			y: -250,
			width: 160,
			height: 250,
			isActive: false,
		};
	}

	wstore.value = { ...wstore.value, ...props.options };

	let parser = new UAParser(navigator.userAgent);
	if (parser.getDevice().type === "mobile") {
		isMobile.value = true;
	}

	if (top === self) {
		shorts.value = await getShortsInfo();

		if (shorts.value) {
			window.l24ToFastStart = true;
			setTimeout(() => {
				initRun();
			}, 1000);
		}

		if (!window.l24idtoLoaTs) {
			toLoad.value = true;
		}
	} else {
		console.log("Shorts24App : iframe에서는 동작하지 않습니다.");
	}
});

onUnmounted(() => {
	try {
		video.removeEventListener("play", () => {});
		video.removeEventListener("pause", () => {});
		video.removeEventListener("volumechange", () => {});
	} catch (e) {}
});
</script>

<template>
	<div class="s24Wrap" v-if="shorts && toLoad" @click="inActive" @mouseleave="outActive">
		<div class="s24Float" style="--animate-duration: 0.2s">
			<DraggableResizableVue
				v-model:x="element.x"
				v-model:y="element.y"
				v-model:h="element.height"
				v-model:w="element.width"
				v-model:active="element.isActive"
				style="
					border: 0;
					border-radius: 15px;
					overflow: hidden;
					filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
					background-color: #cdcdcd;
				"
				:minWidth="120"
				:minHeight="270"
				:maxWidth="200"
				:maxHeight="360"
				:resizable="false"
				:draggable="true"
			>
				<div class="s24btnWarp animate__animated" :class="fadeAni" v-if="activeBtn">
					<div style="display: flex; justify-content: space-between; align-items: center">
						<div></div>
						<div>
							<button
								type="button"
								class="nostyle pointer"
								style="padding: 8px"
								:style="isMobile ? `zoom:0.8` : ''"
								@click="shorts = null"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="22"
									height="22"
									fill="#ffffff"
									viewBox="0 0 256 256"
								>
									<path
										d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"
									></path>
								</svg>
							</button>
						</div>
					</div>
					<div
						class="flex-1"
						style="
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							margin-bottom: 20px;
						"
						:style="{
							gap: isVetical ? '15px' : '5px',
							paddingBottom: isVetical ? '0px' : '10px',
						}"
					>
						<div>
							<div
								style="
									padding: 5px 10px;
									color: #fff;
									font-size: 15px;
									margin-bottom: 10px;
									text-align: center;
								"
								:style="isMobile ? `zoom:0.9` : ''"
							>
								{{ shorts.subject }}
							</div>
							<button
								type="button"
								class="nostyle pointer"
								style="
									padding: 8px 12px;
									color: #fff;
									background-color: red;
									border-radius: 50px;

									display: flex;
									align-items: center;
									gap: 3px;
									margin: auto;
								"
								:style="isMobile ? `font-size:12px` : 'font-size: 13px;'"
								@click="goPlayer"
							>
								<span class="IconEqual"></span>
								라이브 참여하기
							</button>
						</div>
					</div>
				</div>

				<div
					style="position: relative; width: 100%; height: 100%"
					:style="{
						opacity: isVideoReady ? 1 : 0,
					}"
				>
					<div
						style="position: absolute; top: 0; left: 0; right: 0; z-index: 3; width: 100%; height: 100%"
					></div>

					<div
						v-if="!activeBtn"
						style="
							position: absolute;
							left: 7px;
							top: 7px;
							z-index: 9;
							display: flex;
							align-items: center;

							background-color: rgba(0, 0, 0, 0.3);
							color: #fff;
							border-radius: 100px;
							gap: 5px;
						"
						:style="isMobile ? `font-size: 11px;padding-right: 5px;` : 'padding-right: 10px;'"
					>
						<div
							style="
								display: flex;
								align-items: center;
								background-color: red;
								color: #fff;
								border-radius: 100px;
								font-size: 12px;
							"
							:style="isMobile ? `padding: 3px 8px;` : 'padding: 4px 10px;'"
						>
							<div class="IconEqual"></div>
							<div>LIVE</div>
						</div>
						<div style="font-size: 12px">진행중</div>
					</div>

					<UiHls
						:id="'s24lod'"
						:hlsUrl="shorts.hlsPlayUrl"
						:autoplay="true"
						:muted="true"
						:loop="true"
						style="
							width: 100%;
							height: 100%;
							position: absolute;
							left: 0;
							top: 0;
							z-index: 2;
							background-color: #cdcdcd;
						"
						:style="{ objectFit: isVetical ? 'cover' : 'cover' }"
					/>
				</div>
			</DraggableResizableVue>
		</div>
	</div>
</template>

<style scoped>
.pointer {
	cursor: pointer;
}
.nostyle {
	border: none;
	background: none;
}
.flex-1 {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}
.s24btnWarp {
	background-color: rgba(0, 0, 0, 0.51);
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 10;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	cursor: move;
}
.s24Wrap {
	position: relative;
}
.s24Float > video {
	border-radius: 5px;
	filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}
.s24Float {
	width: 100%;

	aspect-ratio: 9/16;
	margin-right: 2.5em;
	margin-bottom: 2em;
	border-radius: 5px;
	position: relative;
}
.closeBtnWarp {
	position: absolute;
	right: -12px;
	top: -14px;
	z-index: 99;
	padding: 5px;
	cursor: pointer;
}

.closeBtn {
	background-color: #000;
	border-radius: 100%;
	width: 30px;
	height: 30px;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
@media (max-width: 768px) {
	.s24Float {
		margin-right: 15px;
		margin-bottom: 80px;
		max-width: 120px;
	}
}
@media (min-width: 768px) {
	.s24Float {
		margin-right: 30px;
		margin-bottom: 80px;
		max-width: 180px;
	}
}

.IconEqual {
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-right: 2px;
	background-image: url(https://tkmenfxu2702.edge.naverncp.com/profile/202401/b5658b47341e97e00d84ac2e2abeda5f.png);
	background-size: 100% 18000%;
	animation: IconEqual_ani 2s steps(180) infinite;
	vertical-align: top;
}

@keyframes IconEqual_ani {
	from {
		background-position: 0 2160px;
	}

	to {
		background-position: 0 0;
	}
}
</style>
