<template>
	<div
		style="width: 100%"
		:style="{
			backgroundColor: wstore.backgroundColor,
		}"
		class="hoverTst l24TraConter l24uvoduskr"
	>
		<div
			v-if="init_load"
			style="margin: auto; width: 100%; position: relative"
			:style="{
				maxWidth: wstore.mobile ? '' : wstore.maxWidth,
			}"
		>
			<div
				style="margin: auto"
				class="l24kid"
				:style="{
					paddingTop: wstore.marginTop,
					paddingBottom: wstore.marginBottom,
					paddingLeft: wstore.mobile ? 0 : wstore.marginLeft,
					paddingRight: wstore.mobile ? 0 : wstore.marginRight,
				}"
			>
				<div
					style="
						margin: 0 0 10px 0;
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
					"
					v-if="wstore.title"
				>
					<div>
						<strong
							class="ft16 vodCardTitle"
							:style="{
								fontSize: wstore.titleFontSize,
								color: wstore.textColor,
								paddingLeft: wstore.mobile ? wstore.marginLeft : '',
							}"
						>
							{{ wstore.title }}
						</strong>
					</div>

					<div style="text-align: right" v-if="wstore.moreLink && wstore.moreText">
						<button
							@click="goLink(wstore.moreLink)"
							:style="{
								fontSize: wstore.moreFontSize,
								color: wstore.textColor,
							}"
							style="opacity: 0.5; background-color: transparent; border: 0"
						>
							{{ wstore.moreText }}
						</button>
					</div>
				</div>

				<div style="margin: auto">
					<div class="cardViewWrap">
						<div
							:style="{
								maxWidth: wstore.mobile ? '100%' : wstore.maxWidth,
							}"
							class=""
						>
							<div
								class="overflow-auto noscroll"
								:id="`${instantId}_scbody`"
								style="scroll-snap-type: x mandatory; position: relative"
							>
								<div
									style="display: flex; flex-direction: row"
									:style="{
										gap: wstore.mobile ? 0 : wstore.cardGap,
										marginRight: wstore.mobile
											? `${Number(wstore.cardGap.replace('px', '').replace('%', ''))}px`
											: '',
									}"
								>
									<div
										style="
											scroll-snap-align: start;
											max-width: 100%;
											max-height: 100%;
											min-width: 200px;
										"
										:style="{
											paddingLeft: wstore.mobile
												? `${Number(wstore.cardGap.replace('px', '').replace('%', ''))}px`
												: '',
										}"
										:id="`${instantId}_${idx + 1}`"
										v-for="(p, idx) in store.vods"
									>
										<vodCard :p="p" :wstore="wstore" />
									</div>

									<div
										v-for="(pp, idx) in sklCnt"
										style="scroll-snap-align: start; max-width: 100%; max-height: 100%"
										:style="{
											minWidth: wstore.mobile
												? `calc((${wstore.maxWidth} / ${wstore.colspan}) + (${wstore.cardGap}  * 1) )`
												: `calc((${wstore.maxWidth} / ${wstore.colspan}) - (${wstore.cardGap}  * 1) + (${wstore.cardGap} / ${wstore.colspan})  )`,
										}"
										:id="`${instantId}_${idx + 1}`"
									>
										<div
											style="width: 100%; margin-bottom: 10px"
											:style="{
												aspectRatio: wstore.aspectRatio,
											}"
										>
											<UiSkl
												width="100%"
												height="100%"
												:borderRadius="wstore.borderRadius"
												style="margin-bottom: 10px"
											/>
										</div>

										<div>
											<UiSkl
												width="100%"
												height="20px"
												borderRadius="10px"
												style="margin-bottom: 10px"
											/>
											<UiSkl width="30%" height="20px" borderRadius="10px" />
										</div>
									</div>
								</div>
							</div>
							<div class="hoverVst">
								<div class="swiper-button-prev" v-if="scrollTab > 1" @click="prev">
									<img
										src="https://tkmenfxu2702.edge.naverncp.com/profile/202311/488111047152155119373963ab6bd7d8.svg"
										alt=""
									/>
								</div>
								<div
									class="swiper-button-next"
									v-if="scrollTab < wstore.limit && vodTotal > wstore.colspan"
									@click="next"
								>
									<img
										src="https://tkmenfxu2702.edge.naverncp.com/profile/202311/bcf37c780fd8c3a8c6c4583183c6e0f7.svg"
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps, onUnmounted } from "vue";
import vodCard from "./ui/vodCard.vue";

import { useL24Store } from "../store/l24";
import UiSkl from "./ui/skl.vue";

const store = useL24Store();
const props = defineProps({
	options: {
		type: Object,
	},
});

//data()
const wstore = ref({
	marginTop: "20px",
	marginBottom: "20px",
	marginLeft: "0",
	marginRight: "0",
	marginLeft2: "0",
	marginRight2: "0",
	maxWidth: "980px",
	title: "",
	titleFontSize: "20px",
	cardGap: "10px",
	colspan: 4,
	limit: 12,
	backgroundColor: "#ffffff",
	textColor: "#000000",
	orderCol: "no",
	mobile: false,
	aspectRatio: "9/12",
	moreText: "더보기",
	moreFontSize: "24px",
	moreLink: "",
});

let init_load = ref(false);
let scrollTab = ref(1);

const generateRandomString = (num) => {
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	let result = "";
	const charactersLength = characters.length;
	for (let i = 0; i < num; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result;
};

const instantId = ref(generateRandomString(10));

let lastLeftPos = 0;
const next = () => {
	try {
		if (scrollTab.value < vodTotal.value) {
			scrollTab.value++;

			if (scrollTab.value > vodTotal.value - wstore.value.colspan) {
				scrollTab.value = vodTotal.value;
			}

			let tbo = document.getElementById(`${instantId.value}_scbody`);
			let leftPos = tbo.scrollLeft + tbo.clientWidth / wstore.value.colspan;

			document.getElementById(`${instantId.value}_scbody`).scrollTo({
				left: leftPos,
				behavior: "smooth",
			});
		}
	} catch (e) {}
};

const prev = () => {
	try {
		if (scrollTab.value > 1) {
			if (scrollTab.value == vodTotal.value) {
				scrollTab.value = scrollTab.value - wstore.value.colspan;
			} else {
				scrollTab.value--;
			}
			let tbo = document.getElementById(`${instantId.value}_scbody`);
			let leftPos = tbo.scrollLeft - tbo.clientWidth / wstore.value.colspan;

			document.getElementById(`${instantId.value}_scbody`).scrollTo({
				left: leftPos,
				behavior: "smooth",
			});
		}
	} catch (e) {}
};
//computed()
const config = computed(() => store.l24config || "");

const vodTotal = computed(() => {
	try {
		return store.vods.length;
	} catch (e) {
		return 0;
	}
});
const sklCnt = computed(() => {
	try {
		let cnt = wstore.value.colspan - store.vods.length;
		if (cnt > 0) {
			return cnt;
		} else {
			return 0;
		}
	} catch (e) {
		return wstore.value.colspan;
	}
});

const setOptions = async (options) => {
	let reload = false;
	try {
		if (options.limit) {
			if (options.limit != wstore.value.limit) {
				reload = true;
			}
		}

		if (options.orderCol) {
			if (options.orderCol != wstore.value.orderCol) {
				reload = true;
			}
		}
	} catch (e) {}

	wstore.value = { ...wstore.value, ...options };

	wstore.value.colspan = parseInt(wstore.value.colspan);
	wstore.value.limit = parseInt(wstore.value.limit);

	if (reload) {
		await store.getVodCardList({
			row_count: wstore.value.limit,
			order_col: wstore.value.orderCol,
			cache_time: 5,
			no_product: true,
		});
	}

	resizeAction();
};

const goLink = (link) => {
	if (wstore.value.privew) {
		alert("미리보기에서는 링크가 동작하지 않습니다.");
	} else {
		location.href = link;
	}
};

const resizeAction = () => {
	let wCwidth = document.querySelector(".l24uvoduskr").clientWidth;

	if (wCwidth < 768) {
		props.options.mobile = true;
	}

	if (props.options.mobile) {
		wstore.value.mobile = true;
		wstore.value.colspan = 2;
		wstore.value.maxWidth = document.querySelector(".hoverTst").clientWidth - 20 + "px";

		wstore.value.cardGap = wstore.value.marginLeft;
	} else {
		wstore.value.mobile = false;
		wstore.value.colspan = props.options.colspan;
		wstore.value.maxWidth = props.options.maxWidth;
	}
};

defineExpose({
	setOptions,
});

onUnmounted(() => {
	window.removeEventListener("resize", () => {});
});
//mounted()
onMounted(async () => {
	//계정 환경설정 로드
	await store.getL24Config();

	wstore.value = { ...wstore.value, ...props.options };

	wstore.value.colspan = parseInt(wstore.value.colspan);
	wstore.value.limit = parseInt(wstore.value.limit);

	resizeAction();

	await store
		.getVodCardList({
			row_count: wstore.value.limit,
			order_col: wstore.value.orderCol,
			cache_time: 5,
			no_product: true,
		})
		.then(() => {
			init_load.value = true;
		});

	window.addEventListener("resize", () => {
		resizeAction();
	});

	//라이블리 특별 조치
	try {
		if (window.l24App.l24Id == "nacf1929") {
			document.getElementById("container").style.overflow = "visible";
		}
	} catch (e) {}
});
</script>

<style scoped>
.l24TraConter {
	container-name: main-container;
	container-type: inline-size;
	width: 100%;
}
.l24 a {
	text-decoration: none;
	color: #000;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

*,
*:before,
*:after {
	box-sizing: border-box;
	font-family: "Noto Sans KR", sans-serif;
	line-height: 1.5;
	font-size: 14px;
}

.cardViewWrap {
}

.row {
}

.wrap {
	width: 100%;
	height: 100%;
	min-height: 300px;
}

.overflow-auto {
	overflow: auto;
	width: 100%;
}

.noscroll::-webkit-scrollbar {
	display: none;
}

.noscroll {
	-ms-overflow-style: none; /* 인터넷 익스플로러 */
	scrollbar-width: none; /* 파이어폭스 */
}

.overflow-hidden {
	overflow: hidden;
	width: 100%;
}

.clearfix {
	clear: both;
}
.pull-left {
	float: left;
}
.pull-right {
	float: right;
}

.grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 10px;
}

@container main-container  (max-width: 768px) {
	.grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr) !important;
		grid-gap: 10px;
	}
}

.grid-gap-1 {
	grid-gap: 10px;
}

.grid-gap-2 {
	grid-gap: 20px;
}

.grid-gap-3 {
	grid-gap: 30px;
}

.grid-gap-4 {
	grid-gap: 40px;
}

.grid-gap-5 {
	grid-gap: 50px;
}

.grid-gap-6 {
	grid-gap: 60px;
}

.grid-cols-1 {
	grid-template-columns: repeat(1, 1fr);
}
.grid-cols-2 {
	grid-template-columns: repeat(2, 1fr);
}
.grid-cols-3 {
	grid-template-columns: repeat(3, 1fr);
}
.grid-cols-4 {
	grid-template-columns: repeat(4, 1fr);
}
.grid-cols-5 {
	grid-template-columns: repeat(5, 1fr);
}
.grid-cols-6 {
	grid-template-columns: repeat(6, 1fr);
}
.grid-cols-7 {
	grid-template-columns: repeat(7, 1fr);
}
.grid-cols-8 {
	grid-template-columns: repeat(8, 1fr);
}
.grid-cols-9 {
	grid-template-columns: repeat(9, 1fr);
}
.grid-cols-10 {
	grid-template-columns: repeat(10, 1fr);
}
.pos_r {
	position: relative;
}
.pos_a {
	position: absolute;
}

.swiper-button-prev {
	position: absolute;
	left: -25px;
	top: calc(50% - 25px);

	width: 40px;
	height: 40px;
	z-index: 2;
	border-radius: 100%;
	background-color: rgba(236, 233, 233, 0.83);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.swiper-button-next {
	position: absolute;
	right: -25px;
	top: calc(50% - 25px);

	width: 40px;
	height: 40px;
	z-index: 2;
	border-radius: 100%;
	background-color: rgba(236, 233, 233, 0.83);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.hoverVst {
	display: none;
}

@media (min-width: 576px) {
	.hoverTst:hover .hoverVst {
		display: block;
	}
}

.hoverTst .swiper-button-next,
.hoverTst .swiper-button-prev {
	background-image: none !important;
}
</style>
