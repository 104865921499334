const config = {
	name: "live24Code",
	ver: "0.0.1",
	apis: {
		live: "https://[#L24ID].live24.app/api/v3/json/list/live",
		vod: "https://[#L24ID].live24.app/api/v3/json/list/vod",
		config: "https://[#L24ID].live24.app/api/v3/json/list/config",
		trailer: "https://[#L24ID].live24.app/api/v3/json/list/trailer",
		liveState: "https://[#L24ID].live24.app/api/v3/json/info/state",
		settings: "https://[#L24ID].live24.app/api/v3/json/list/shorts",
	},
	cafe24: {
		client_id: "QFf0CgssYa4YTLAyyi7hJC", // 사용할 앱의 App Key를 설정해 주세요.
		version: "2022-06-01", // 적용할 버전을 설정해 주세요.
	},
};

module.exports = config;
