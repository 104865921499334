<script setup>
import _ from "lodash";
import { ref, computed, onMounted, markRaw, onUnmounted, watch, defineProps } from "vue";
import UiHls from "./hls.vue";
const props = defineProps({
	f: {
		type: Object,
		required: true,
	},
	idx: {
		type: Number,
		required: true,
	},
	activeNo: {
		type: Number,
	},
	PS: {
		type: Object,
		required: true,
	},
});

const emit = defineEmits(["setActiveNo"]);

const initLoad = ref(false);
const isActive = ref(false);

const setActive = () => {
	isActive.value = true;
	emit("setActiveNo", props.f.no);
};

const ovCallBackVideo = async (e) => {
	if (initLoad.value) {
		emit("setActiveNo", e[0].target.id.replace("ovVideoFrame_", ""));
	}
};

onMounted(() => {
	let io2 = new IntersectionObserver(ovCallBackVideo, {
		root: document.getElementById("l24Wmickf2"),
		rootMargin: "-30px 0px -100px 0px",
		threshold: 1,
	});

	io2.observe(document.getElementById(`ovVideoFrame_${props.f.no}`));

	if (props.idx < 1) {
		initLoad.value = true;
	} else {
		setTimeout(() => {
			initLoad.value = true;
		}, 300);
	}
});
</script>

<template>
	<div class="relative postCardThumb" :id="`ovVideoFrame_${f.no}`">
		<div class="backdrop absolute" style="top: 0; left: 0">
			<div style="text-align: center">
				<div style="text-align: center; zoom: 1.2; line-height: 180%" v-if="f.type == 'trailer'">
					<strong class="text-white text-md">라이브예정</strong>
				</div>
				<div style="text-align: center; zoom: 1.2; line-height: 180%" v-if="f.type == 'vod'">
					<strong class="text-white text-md">다시보기</strong>
				</div>
				<div style="text-align: center; zoom: 1.2; line-height: 180%" v-if="f.type == 'shorts'">
					<strong class="text-white text-md">숏폼</strong>
				</div>

				<div class="text-white text-md" v-if="f.type == 'live'">
					<div class="flex justify-center">
						<div class="liveBadge">
							<div class="IconEqual"></div>
							<div style="color: #fff">LIVE</div>
						</div>
					</div>
					<div style="text-align: center; zoom: 1.2; line-height: 180%" class="mt-2">
						<strong class="text-white text-md">라이브 진행중</strong>
					</div>
				</div>

				<div class="mt-2" v-if="f.type != 'live'">
					<strong class="text-white text-xl" v-html="f.ui.onair_start_txt"></strong>
				</div>
				<div v-else>
					<button
						type="button"
						style="
							border: 1px solid #000;
							border-radius: 50px;
							padding: 8px 15px;
							color: #fff;
							background-color: #000;
							cursor: pointer;
						"
					>
						참여하기
					</button>
				</div>
			</div>
		</div>
		<div v-if="activeNo == f.no" class="absolute" style="z-index: 3; top: 0; left: 0">
			<video
				v-if="f.type == 'vod' || f.type == 'shorts'"
				:src="f.ui.thumb_video_url"
				autoplay
				playsinline
				muted
				loop
				class="w-full h-full"
				style="object-fit: cover"
				poster="https://tkmenfxu2702.edge.naverncp.com/profile/202404/37ea57d089b7254331d3f8098b0b1b45.png"
				:style="{
					background: `url(${f.ui.thumb_video_url}) center center / cover no-repeat`,
				}"
			></video>

			<UiHls
				v-else
				:hlsUrl="`https://zoipt8ss2701.edge.naverncp.com/live/video/${PS.ui.channel_id}/360p-9-16-2/playlist.m3u8`"
				:autoplay="true"
				:muted="true"
				:aspectRatio="'9/12'"
				:poster="f.thumb_url"
				:id="`card_${f.no}`"
			/>
		</div>
		<div class="absolute" style="z-index: 2; top: 0; left: 0">
			<img
				src="https://tkmenfxu2702.edge.naverncp.com/profile/202404/37ea57d089b7254331d3f8098b0b1b45.png"
				:style="{
					background: `url(${f.ui.thumb_url}) center center / cover no-repeat`,
					backgroundColor: '#000',
				}"
				class="w-full h-full"
				alt=""
				style="object-fit: cover"
			/>
		</div>
	</div>
</template>

<style scoped>
.text-lg {
	font-size: 20px;
}
.mt-2 {
	margin-top: 5px;
}
.liveBadge {
	display: flex;
	background-color: red;
	font-size: 13px;
	color: #fff;
	align-items: center;
	justify-content: center;
	padding: 2px 10px;
	border-radius: 20px;
	margin-right: 5px;
}

.IconEqual {
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-right: 2px;
	background-image: url(https://tkmenfxu2702.edge.naverncp.com/profile/202401/b5658b47341e97e00d84ac2e2abeda5f.png);
	background-size: 100% 18000%;
	animation: IconEqual_ani 2s steps(180) infinite;
	vertical-align: top;
}

@keyframes IconEqual_ani {
	from {
		background-position: 0 2160px;
	}

	to {
		background-position: 0 0;
	}
}
.bg-black {
	background-color: #000000;
}
.text-white {
	color: white;
}
.w-full {
	width: 100%;
}
.h-full {
	height: 100%;
}
.postCardThumb {
	cursor: pointer;
}
.postCardThumb > div {
	width: 100%;
	height: 100%;
}
.backdrop {
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.postCardThumb {
	aspect-ratio: 2/2.5;
	width: 100%;
	object-fit: cover;
	border-radius: 10px;
	overflow: hidden;
}
.text-sm {
	font-size: 0.8rem;
}
.text-md {
	font-size: 14px;
}
.text-lg {
	font-size: 20px;
}
.text-xl {
	font-size: 24px;
}
.text-2xl {
	font-size: 32px;
}

.flex-1 {
	flex: 1;
}
.flex {
	display: flex;
}
.justify-center {
	justify-content: center;
}
.items-center {
	align-items: center;
}
</style>
