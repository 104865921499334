<template>
	<div v-if="init_load" class="l24TraConter">
		<div
			class="w-full"
			:style="{
				backgroundColor: wstore.backgroundColor,
				color: wstore.textColor,
			}"
		>
			<div style="display: flex; justify-content: center" v-if="tra" :class="{ 'l24-mobile': wstore.mobile }">
				<div
					class="Trawrap l24 w-full"
					style="display: flex; justify-content: center; align-items: center"
					:style="{
						paddingTop: wstore.marginTop,
						paddingBottom: wstore.marginBottom,
						paddingLeft: wstore.marginLeft,
						paddingRight: wstore.marginRight,
						maxWidth: wstore.maxWidth,
					}"
				>
					<div class="contentWarp">
						<div class="vodCardTitleBody" v-if="wstore.title">
							<strong
								class="ft16 vodCardTitle"
								:style="{ fontSize: wstore.titleFontSize, color: wstore.textColor }"
							>
								{{ wstore.title }}
							</strong>
						</div>
						<div class="grid items-center">
							<div class="leftWrap mb-3">
								<div
									class="pos_r"
									:style="{
										background: `url(${tra.thumb_url}) center / cover`,
										aspectRatio: wstore.aspectRatio,
										overflow: 'hidden',
										borderRadius: wstore.borderRadius,
									}"
								>
									<div class="backdrop thumbd">
										<div class="text-center">
											<div>
												<strong style="color: #ffffff; font-size: 20px">{{
													startDate2
												}}</strong>
											</div>
											<div>
												<strong style="color: #ffffff; font-size: 40px">{{ startTime }}</strong>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="rightWrap mb-3">
								<div>
									<div class="pl-2 productListWrap" style="">
										<div>
											<strong class="vodr_box">LIVE 예정</strong>
										</div>
										<div class="mt-2 el-text2">
											<strong class="liveTitle" :style="{ color: wstore.textColor }">
												{{ tra.subject }}
											</strong>
										</div>
										<div
											class="smalltitle mt-1 el-text2"
											style="line-height: 180%"
											:style="{ color: wstore.textColor }"
											v-html="tra.content"
										></div>

										<div class="mt-3">
											<div v-for="(p, i) in pin_product" class="mb-3">
												<UiProductCol :p="p" v-if="i < 4" :wstore="wstore" />
											</div>
											<div v-for="(p, i) in products" class="mb-3">
												<UiProductCol :p="p" v-if="i < 4" :wstore="wstore" />
											</div>

											<div v-if="!products && wstore.privew">
												<div
													class="flex items-center"
													style="margin-bottom: 15px"
													v-for="i in 3"
												>
													<div>
														<UiSkl width="78px" height="78px" borderRadius="5px" />
													</div>
													<div style="padding-left: 15px">
														<div style="margin-bottom: 10px">
															<UiSkl width="90px" height="20px" borderRadius="5px" />
														</div>
														<div>
															<UiSkl width="30px" height="20px" borderRadius="5px" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div v-else>
		<div
			class="w-full"
			:style="{
				backgroundColor: '#e8e8e8',
				color: wstore.textColor,
			}"
		>
			<div style="display: flex; justify-content: center" :class="{ 'l24-mobile': wstore.mobile }">
				<div
					class="Trawrap l24 w-full"
					:style="{
						paddingTop: wstore.marginTop,
						paddingBottom: wstore.marginBottom,
						paddingLeft: wstore.marginLeft,
						paddingRight: wstore.marginRight,
						maxWidth: wstore.maxWidth,
					}"
				>
					<div class="contentWarp">
						<div class="vodCardTitleBody">
							<strong class="ft16 vodCardTitle">
								<UiSkl width="200px" height="26px" borderRadius="15px" :wave="true" />
							</strong>
						</div>

						<div class="grid items-center">
							<div class="leftWrap mb-3">
								<div
									class="pos_r"
									:style="{
										aspectRatio: wstore.aspectRatio,
										overflow: 'hidden',
										borderRadius: wstore.borderRadius,
									}"
								>
									<div class="backdrop thumbd">
										<div class="text-center">
											<div style="margin-bottom: 20px">
												<strong class="ft20">
													<UiSkl
														width="100px"
														height="28px"
														borderRadius="15px"
														style="margin: auto"
														:wave="true"
													/>
												</strong>
											</div>
											<div>
												<UiSkl width="200px" height="36px" borderRadius="15px" :wave="true" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="rightWrap mb-3">
								<div>
									<div class="pl-2 productListWrap" style="">
										<div>
											<UiSkl width="100px" height="28px" borderRadius="15px" :wave="true" />
										</div>
										<div class="mt-2 el-text2">
											<strong class="liveTitle">
												<UiSkl width="200px" height="36px" borderRadius="15px" :wave="true" />
											</strong>
										</div>
										<div class="smalltitle mt-1 el-text2" style="line-height: 180%">
											<div style="margin-bottom: 10px">
												<UiSkl width="100%" height="26px" borderRadius="15px" :wave="true" />
											</div>
											<div>
												<UiSkl width="40%" height="26px" borderRadius="15px" :wave="true" />
											</div>
										</div>

										<div class="mt-3">
											<div>
												<div
													class="flex items-center"
													style="margin-bottom: 15px"
													v-for="i in 3"
												>
													<div>
														<UiSkl
															width="78px"
															height="78px"
															borderRadius="5px"
															:wave="true"
														/>
													</div>
													<div style="padding-left: 15px">
														<div style="margin-bottom: 10px">
															<UiSkl
																width="90px"
																height="20px"
																borderRadius="5px"
																:wave="true"
															/>
														</div>
														<div>
															<UiSkl
																width="30px"
																height="20px"
																borderRadius="5px"
																:wave="true"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps } from "vue";
import UiProductCol from "./ui/productCol.vue";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

import { useL24Store } from "../store/l24";
import UiSkl from "./ui/skl.vue";
const store = useL24Store();

const props = defineProps({
	options: {
		type: Object,
	},
});

//data()
const wstore = ref({
	titleFontSize: "24px",
	marginTop: "20px",
	marginBottom: "20px",
	marginLeft: "20px",
	marginRight: "20px",
	maxWidth: "980px",
	aspectRatio: "9/12",
	borderRadius: "20px",
	privew: false,
});
let init_load = ref(false);

let bannerUrl = ref("");

const startDate2 = computed(() => {
	if (tra) {
		return dayjs(tra.value.onair_start).format("MM.DD (ddd)");
	} else {
		return "";
	}
});

const startTime = computed(() => {
	if (tra) {
		return dayjs(tra.value.onair_start).format("HH:mm");
	} else {
		return "";
	}
});

const tra = computed(() => {
	try {
		return store.trailer[0];
	} catch (e) {
		if (wstore.value.privew) {
			return {
				title: "라이브 예정",
				thumb_url: "",
				subject: "표시할 내용이 없습니다.",
				content:
					"라이브 템플릿이 등록되면 내용이 표시됩니다.<br>현재는 미리보기 모드입니다. 실제 위젯에서는 노출되지 않습니다.",
			};
		}
	}
});
const pin_product = computed(() => {
	try {
		let list = store.trailer[0].select_product_list;
		if (list) {
			let arr = _.filter(list, (o) => {
				return parseInt(o.no) == parseInt(tra.value.pin_product);
			});

			return arr;
		} else {
			return [];
		}
	} catch (e) {}
});
const products = computed(() => {
	try {
		let list = store.trailer[0].select_product_list;
		if (list) {
			let arr = _.filter(list, (o) => {
				return parseInt(o.no) != parseInt(tra.value.pin_product);
			});

			return arr;
		} else {
			return store.trailer[0].select_product_list;
		}
	} catch (e) {}
});

const config = computed(() => store.l24config || "");

// const addCalendar = () => {
// 	window.open(
// 		`https://calendar.google.com/calendar/render?action=TEMPLATE&ctz=Asia/Seoul&text=[라이브 참여] ${
// 			store.l24config.site_name ? store.l24config.site_name : store.l24config.nickname
// 		}&dates=${dayjs(store.trailer.onair_start).format("YYYYMMDDTHHMMSS")}/${dayjs(store.trailer.onair_start)
// 			.add(1, "hours")
// 			.format("YYYYMMDDTHHMMSS")}&details=${store.trailer.subject} - ${store.trailer.shut_url}`,
// 		"calpop",
// 		"width=800,height=600,scrollbars=yes"
// 	);
// };

const liveTitle = ref("라이브 예정");

const setOptions = (options) => {
	wstore.value = { ...wstore.value, ...options };
};

defineExpose({
	setOptions,
});

//mounted()
onMounted(async () => {
	//계정 환경설정 로드
	await store.getL24Config();

	wstore.value = { ...wstore.value, ...props.options };

	try {
		if (wstore.value.bannerUrl) bannerUrl = wstore.value.bannerUrl;
	} catch (e) {}

	await store.getTrailerFloatBanner().then(() => {
		try {
			if (wstore.value.title) liveTitle = wstore.value.title;
		} catch (e) {}

		init_load.value = true;
	});
});
</script>

<style scoped>
.l24TraConter {
	container-name: main-container;
	container-type: inline-size;
}

.l24 a {
	text-decoration: none;
	color: #000;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
.contentWarp {
	width: 100%;
	max-width: 980px;
	margin: auto;
}
*,
*:before,
*:after {
	box-sizing: border-box;
	font-family: "Noto Sans KR", sans-serif;
	line-height: 1.5;
	font-size: 14px;
}

.Trawrap {
	max-width: 1600px;
	margin: auto;
}

.backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 2;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.leftWrap {
	position: relative;
}

.vodr_box {
	background: red;
	color: #fff;
	font-size: 18px;
	letter-spacing: 0;
	font-weight: 600;
	margin-right: 5px;
	display: inline-block;
	line-height: 1.3;
	border-radius: 20px;
	padding: 0.5px 8px 0 8px;
}

.liveTitle {
	font-size: 24px;
	font-weight: 800;
	margin-bottom: 0.1rem;
	letter-spacing: -0.1rem;
	font-family: Noto Sans KR;
}

.smalltitle {
	letter-spacing: -0.1rem;
	line-height: 1;
	opacity: 0.9;
	font-size: 16px;
}

.el-text2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	max-height: 68px;

	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.profile_img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}

@container main-container  (min-width: 728px) {
	.productListWrap {
		height: 100%;
		overflow-y: auto;
	}

	.leftWrap {
		position: relative;
		max-width: 480px;
	}

	.rightWrap {
		padding: 0 20px;
	}
	.vodCardTitleBody {
		margin: 25px 0 25px 0;
	}
}

@container main-container  (max-width: 728px) {
	.Trawrap {
		justify-content: center;
	}

	.leftWrap {
		position: relative;
	}
	.productListWrap {
		max-height: max-content !important;
		overflow: visible !important;
	}
	.vodCardTitleBody {
		margin-top: 25px;
		margin-bottom: 10px;
	}

	.rightWrap {
		/*padding: 0 10px;*/
	}
}

.thumbd {
	aspect-ratio: 9/10;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
}

.l24-mobile .vodCardTitleBody {
	/*margin-left: 15px;
	margin-right: 15px;*/
}
.l24-mobile .productListWrap {
	max-height: max-content !important;
	overflow: visible !important;
}

.l24-mobile .Trawrap {
	justify-content: center;
}

.l24-mobile .leftWrap {
	position: relative;
}

.w-full {
	width: 100%;
}

.h-full {
	height: 100%;
}

.flex {
	display: flex;
}

.items-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.text-center {
	text-align: center;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.pos_r {
	position: relative;
}
.pos_a {
	position: absolute;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}
.grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
}

@media screen and (max-width: 768px) {
	.grid {
		display: grid;
		grid-template-columns: repeat(1, 1fr) !important;
		grid-gap: 10px;
	}
}

.l24-mobile .grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr) !important;
	grid-gap: 10px;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}
.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}
.ft20 {
	font-size: 20px;
}
.ft40 {
	font-size: 40px;
}
.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.mt-3,
.my-3 {
	margin-top: 1rem !important;
}
.mt-1,
.my-1 {
	margin-top: 0.25rem !important;
}
.overflow-h {
	overflow: hidden;
}
.r20 {
	border-radius: 20px;
}

.mt-2,
.my-2 {
	margin-top: 0.5rem !important;
}
</style>
