<script setup>
import UiOvCard from "../../components/ui/ovCard.vue";
import axios from "axios";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js"; // import plugin
import utc from "dayjs/plugin/utc.js";
dayjs.extend(timezone); // use plugin
dayjs.extend(utc); // use plugin

import { ref, computed, onMounted, markRaw, onUnmounted, watch, defineProps } from "vue";
const props = defineProps({
	PS: {
		type: Object,
	},
	config: {
		type: Object,
	},
	wstore: {
		type: Object,
	},
});

const activeNo = ref(null);
const emit = defineEmits("popupClose");

const isScroll = ref(false);
const init_load = ref(false);
const nextKey = ref("");
const isEnd = ref(false);
const loading = ref(false);
const FEED = ref([]);
const initFeedRun = ref(false);

const setActiveNo = (no) => {
	if (activeNo.value != no) {
		activeNo.value = no;
	}
};
const getPlaylist = async () => {
	if (isEnd.value) return;

	loading.value = true;
	console.log(window.l24UserNo)
	let res = await axios.get(`https://live24.app/shorts24/api/playlist`, {
		params: {
			user_no: props.config.user_no ? props.config.user_no : window?.l24UserNo,
			postkey: nextKey.value ? nextKey.value : null,
		},
	});

	if (res.data.code == 200) {
		if(res.data.result.data){
			for (let i = 0; i < res.data.result.data.length; i++) {
				res.data.result.data[i].smallView = true;
				try {
					res.data.result.data[i].products = JSON.parse(res.data.result.data[i].select_product_json);
				} catch (e) {
					res.data.result.data[i].products = [];
				}
				if (res.data.result.data[i].type == "vod" || res.data.result.data[i].type == "shorts") {
					res.data.result.data[i].ui.onair_start_txt = dayjs(res.data.result.data[i].ui.startTime).format(
						"MM월 DD일<br>HH:mm"
					);
				} else {
					let hw = dayjs(res.data.result.data[i].ui.onair_start).format("HH") >= 12 ? "오후" : "오전";
					res.data.result.data[i].ui.onair_start_txt = dayjs(res.data.result.data[i].ui.onair_start).format(
						`MM월 DD일<br>${hw} hh:mm`
					);
				}

				if (nextKey.value) {
					if (res.data.result.data[i].type == "vod" || res.data.result.data[i].type == "shorts") {
						FEED.value.push(res.data.result.data[i]);
					}
				} else {
					FEED.value.push(res.data.result.data[i]);
				}
			}
		} else {
			return null;
		}


		if (res.data.result.end) {
			isEnd.value = true;
		}
		nextKey.value = res.data.result.key;
	}
	if (!initFeedRun.value) {
		try {
			const io = new IntersectionObserver(ovCallBack, { threshold: 0.7 });
			io.observe(document.getElementById("ovTargetDiv"));
		} catch (e) {}
	}

	initFeedRun.value = true;
	setTimeout(() => {
		loading.value = false;
		init_load.value = true;
	}, 500);
};

const ovCallBack = async () => {
	if (init_load.value && !isEnd.value && !loading.value) {
		console.log("!!ovCallBack");
		loading.value = true;
		await getPlaylist();
	}
};

const playerUrl = computed(() => {
	try {
		if (props.PS?.l24Cloud?.installCheck) {
			let domainw = props.PS?.l24Cloud?.domain?.pc;
			if (domainw == top.location.origin) {
				domainw = "";
			}

			if (props.PS?.l24Cloud?.installUrl.substring(0, 1) == "/") {
				return domainw + props.PS?.l24Cloud?.installUrl;
			} else {
				return domainw + "/" + props.PS?.l24Cloud?.installUrl;
			}
		} else {
			return "";
		}
	} catch (e) {
		return "";
	}
});

const getQueryVariable = (variable) => {
	try {
		let query = top.location.search.substring(1);
		let vars = query.split("&");
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split("=");
			if (pair[0] == variable) {
				return pair[1];
			}
		}
		return "";
	} catch (e) {
		return "";
	}
};
const goContent = (post) => {
	if (getQueryVariable("zoom")) {
		alert(`미리보기에서는 콘텐츠로 이동하지 않습니다.`);
		return;
	}
	if (!playerUrl.value) {
		alert(`LIVE24 플레이어가 감지되지 않습니다.`);
		return;
	}
	let andTag = playerUrl.value.indexOf("?") > -1 ? "&" : "?";
	if (post.ui.type == "vod" || post.ui.type == "shorts") {
		location.href = playerUrl.value + `${andTag}content_no=` + post.no;
	} else if (post.ui.type == "live") {
		location.href = playerUrl.value + `${andTag}tr_no=` + post.tr_no;
	} else {
		location.href = playerUrl.value;
	}
};

onMounted(async () => {
	await getPlaylist();

	setTimeout(() => {
		document.querySelector(".microW").addEventListener("scroll", (e) => {
			if (e.target.scrollTop > 50) {
				isScroll.value = true;
			} else {
				isScroll.value = false;
			}
		});
	}, 500);
});
</script>

<template>
	<div class="min-w-[380px] min-h-[500px] relative text-md" :style="FEED && FEED.length > 0 ?'' : 'height:100%'">
		<div
			class="mobile absolute bg-white"
			style="
				top: 20px;
				right: 15px;
				border-radius: 100px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 28px;
				height: 28px;
			"
			@click="emit('popupClose')"
		>
			<img
				src="https://tkmenfxu2702.edge.naverncp.com/profile/202404/6fd67d9417b4f3422ccd27af5ea99611.svg"
				alt=""
				@click="emit('popupClose')"
				style="width: 20px; height: 20px"
			/>
		</div>
		<div
			class="flex items-center gap-5 border-b"
			style="
				position: sticky;
				top: 0;
				width: 100%;
				background-color: #fff;
				padding: 15px 0px 10px 0px;
				z-index: 20;
			"
			v-if="isScroll"
		>
			<div style="padding-left: 20px">
				<img
					:src="PS?.ui?.profile_image_url"
					class="object-cover avtar"
					alt=""
					style="width: 20px; height: 20px"
				/>
			</div>
			<div class="flex-1">
				<strong class="text-md">{{ PS?.ui?.site_name }}</strong>
			</div>
			<div style="padding-right: 20px">
				<img
					src="https://tkmenfxu2702.edge.naverncp.com/profile/202404/6fd67d9417b4f3422ccd27af5ea99611.svg"
					alt=""
					@click="emit('popupClose')"
					style="width: 17px; height: 17px"
				/>
			</div>
		</div>
		<div class="flex items-center pt-4 pb-2 px-4 gap-10">
			<div>
				<img :src="PS?.ui?.profile_image_url" class="object-cover avtar" alt="" />
			</div>
			<div class="">
				<div class="mb-1">
					<strong class="text-lg">{{ PS?.ui?.site_name }}</strong>
				</div>
				<div>
					{{ wstore?.description ? wstore?.description : "라이브커머스에 오신걸 환영합니다." }}
				</div>
			</div>
		</div>

		<div class="space-y-3 px-4 mt-2" :style="FEED && FEED.length > 0 ? '' : 'height: calc(100% - 130px)'">
			<div v-if="FEED && FEED.length > 0">
				<div class="bg-white rounded-md shadow-md card" style="padding-top: 5px" v-for="(f, i) in FEED">
					<div class="py-3 px-4">
						<div class="mb-3">
							<strong class="text-md">{{ f.ui.subject }}</strong>
						</div>
						<UiOvCard
							:f="f"
							:idx="i"
							:activeNo="activeNo"
							@setActiveNo="setActiveNo"
							:PS="PS"
							@click="goContent(f)"
							style="cursor: pointer"
						/>
						<div class="space-y-2">
							<div style="padding-top: 5px; padding-bottom: 10px" class="transition">
								<div
									style="padding-top: 10px"
									v-if="f.ui.content"
									v-html="f.ui?.content?.replaceAll('\n\n', '\n').replaceAll('\n', '<br />')"
								></div>

								<div
									style="margin-top: 20px"
									class="space-y-2 relative pointer"
									:class="{
									'smallViewProduct maskTs': f.smallView && f.products.length > 2,
								}"
									@click="f.smallView = false"
								>
									<div v-for="(p, ii) in f.products" class="flex gap-10 items-center">
										<div>
											<img :src="p.product_image_url" class="productImage" alt="" />
										</div>
										<div class="space-y-2">
											<div class="el2">{{ p.product_name }}</div>
											<div class="">{{ parseInt(p.price).toLocaleString() }}원</div>
										</div>
									</div>
								</div>
							</div>

							<div class="flex gap-10">
								<div class="flex-1">
									<button type="button" class="btn-gray" @click="goContent(f)">
										<span v-if="f.type == 'live'">라이브참여</span>
										<span v-else-if="f.type == 'shorts'">숏폼보기</span>
										<span v-else-if="f.type == 'vod'">다시보기</span>
										<span v-else>자세히보기</span>
									</button>
								</div>
								<!--							<div class="flex-1"><button type="button" class="btn-gray">공유하기</button></div>-->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="bg-white p-4 rounded-md flex items-center justify-center" style="height: 100%; margin-bottom: 1rem">
				<div>
					<div class=" flex items-center justify-center">
						<img src="https://tkmenfxu2702.edge.naverncp.com/profile/202407/2101b4c844bc13986a60663a49dbfd68.png" alt="" style="width: 130px; height: 130px;">
					</div>
					<div class="flex items-center justify-center" style="margin-top: 1rem">
						<div class="text-center ft14" style="font-weight: 500">
							아직 등록된 라이브 방송이 없습니다.
						</div>
					</div>
				</div>
			</div>
			<div class="obzDiv" id="ovTargetDiv" v-if="!isEnd"></div>
		</div>
	</div>
</template>

<style scoped>
.pointer {
	cursor: pointer;
}
.text-white {
	color: #fff;
}
@media screen and (max-width: 768px) {
	.mobile {
	}
}
@media screen and (min-width: 768px) {
	.mobile {
		display: none !important;
	}
}
.transition {
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}
.smallViewProduct {
	max-height: 140px;
	overflow: hidden;
	overscroll-behavior: none;
	will-change: bottom;
}
.maskTs {
	-webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
	mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}
.btn-gray {
	width: 100%;
	height: 40px;
	border: none;
	background-color: #f1f1f1;
	color: #000;
	font-size: 12px;
	font-weight: 500;
	border-radius: 10px;
	cursor: pointer;
}
.flex-1 {
	flex: 1;
}
.obzDiv {
	height: 2px;
	width: 100%;

	bottom: 40px;
}
.feedThumb {
	width: 100%;
}
.pull-left {
	float: left;
}
.clearfix {
	clear: left;
}
.grid {
	display: grid;
}
.grid-cols-10 {
	grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid-gap-5 {
	grid-gap: 5px;
}
.grid-gap-10 {
	grid-gap: 10px;
}
.productImage {
	width: 50px;
	aspect-ratio: 1 / 1;
	object-fit: cover;
	border-radius: 5px;
}
.border-t {
	border-top: 1px solid rgba(229, 231, 235, 0.64);
}
.border-b {
	border-bottom: 1px solid rgba(229, 231, 235, 0.64);
}

.justify-between {
	justify-content: space-between;
}
.text-white {
	color: white;
}
.backdrop {
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
}
.el2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	line-height: 1.2em;
	max-height: 1.2em;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.el4 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	line-height: 1.3em;
	min-height: 1.5em;
	max-height: 2.8em;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.pt-4 {
	padding-top: 1.5rem !important;
}
.pb-4 {
	padding-bottom: 1.5rem !important;
}
.pb-3 {
	padding-bottom: 1rem !important;
}
.pb-2 {
	padding-bottom: 0.5rem !important;
}
.py-3 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.py-4 {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}
.p-4 {
	padding: 1.2rem;
}
.px-3 {
	padding-left: 1rem;
	padding-right: 1rem;
}
.px-4 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.rounded-md {
	border-radius: 10px;
}
.card {
}
.bg-white {
	background-color: white;
}
.mb-1 {
	margin-bottom: 0.25rem;
}
.mb-2 {
	margin-bottom: 10px;
}
.mb-3 {
	margin-bottom: 1rem;
}
.space-y-2 > * {
	margin-bottom: 0.5rem;
}
.space-y-2:last-child,
.space-y-3:last-child {
	margin-bottom: 0;
}
.space-y-3 > * {
	margin-bottom: 0.75rem;
}
.text-sm {
	font-size: 0.8rem;
}
.text-md {
	font-size: 14px;
}
.text-lg {
	font-size: 20px;
}
.text-xl {
	font-size: 24px;
}
.text-2xl {
	font-size: 32px;
}
.gap-1 {
	gap: 1px;
}
.gap-2 {
	gap: 2px;
}
.gap-3 {
	gap: 3px;
}
.gap-4 {
	gap: 4px;
}
.gap-5 {
	gap: 5px;
}
.gap-6 {
	gap: 6px;
}
.gap-7 {
	gap: 7px;
}
.gap-8 {
	gap: 8px;
}
.gap-9 {
	gap: 9px;
}
.gap-10 {
	gap: 10px;
}
.gap-15 {
	gap: 15px;
}
.p-3 {
	padding: 1rem;
}
.avtar {
	width: 60px;
	height: 60px;
	border-radius: 20px;
	border: 1px solid #f1f1f1;
}
.w-full {
	width: 100%;
}
.h-full {
	height: 100%;
}
.drop-shadow-lg {
	filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}
.drop-shadow-2xl {
	filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}
.overflow-hidden {
	overflow: hidden;
}
.l24-ring {
	width: 60px;
	height: 60px;
}
.object-contain {
	object-fit: contain;
}
.object-cover {
	object-fit: cover;
}
.aspect-ratio-1 {
	aspect-ratio: 1/1;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
.bottom-0 {
	bottom: 0;
}
.right-0 {
	right: 0;
}
.flex {
	display: flex;
}
.justify-center {
	justify-content: center;
}
.items-center {
	align-items: center;
}
</style>
