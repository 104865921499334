import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyAU5ZT4XTQylQyfuDSznIaae_bIMnOpKH8",
	authDomain: "live24-player-v3.firebaseapp.com",
	databaseURL: "https://live24-player-v3-default-rtdb.asia-southeast1.firebasedatabase.app",
	projectId: "live24-player-v3",
	storageBucket: "live24-player-v3.appspot.com",
	messagingSenderId: "439129890925",
	appId: "1:439129890925:web:0b30c3fc6c99a29b9a9f64",
	measurementId: "G-495J2F9VEV",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const l24WidgetRef = require("./l24Widget");

window.l24Widget = [];
window.noLive24OldRun = false;
window.l24App = "";
window.l24App = new l24WidgetRef.default();

window.user_key = sessionStorage.getItem("USER_KEY");

try {
	l24LoginPageUrl = l24LoginPageUrl || "";
} catch (e) {
	let l24LoginPageUrl = "";
}

try {
	l24_site_key = l24_site_key || "";
} catch (e) {
	let l24_site_key = "";
}

//폰트 아이콘 삽입
setTimeout(() => {
	var head = document.getElementsByTagName("head")[0];
	var script = document.createElement("script");

	script.type = "text/javascript";
	script.src = "https://unpkg.com/@phosphor-icons/web@2.0.3/src/index.js";
	head.appendChild(script);
}, 200);
