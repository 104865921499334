<template>
	<Transition name="fade">
		<div v-if="init_load" class="flex justify-center l24TraConter">
			<div
				class="l24 w-full"
				:style="{
					paddingTop: wstore.marginTop,
					paddingBottom: wstore.marginBottom,
					paddingLeft: wstore.marginLeft,
					paddingRight: wstore.marginRight,
					maxWidth: wstore.maxWidth,
				}"
			>
				<div style="margin: 25px 0 25px 0" v-if="wstore.title">
					<strong
						class="ft16 vodCardTitle"
						:style="{ fontSize: wstore.titleFontSize, color: wstore.textColor }"
					>
						{{ wstore.title }}
					</strong>
				</div>
				<div class="row items-center mb-5" v-for="(v, i) in store.vods">
					<div class="leftWrap col-12 col-md-6 mb-3 hand" @click="goVod(v.ui)">
						<div class="r20 overflow-h pos_r">
							<div class="backdrop">
								<div class="text-center">
									<div>
										<strong class="ft20">{{ ToDateFormat(v.ui.startTime, "MM.DD") }}</strong>
									</div>
									<div>
										<strong class="ft40">{{ ToDateFormat(v.ui.startTime, "HH:mm") }}</strong>
									</div>

									<div>
										<strong class="ft20">방송완료</strong>
									</div>
								</div>
							</div>
							<img :src="v.ui.thumb_url" class="w-full h-full thumbBox" style="" alt="" />
						</div>
					</div>
					<div class="col-12 col-md-6 rightWrap mb-3 rightWrap">
						<div>
							<div class="pl-2" style="height: calc(500px - 20px); overflow: hidden">
								<div>
									<strong class="vodr_box">VOD</strong>
								</div>
								<div class="mt-2 el-text2">
									<strong class="liveTitle" style="color: inherit">
										{{ v.ui.subject }}
									</strong>
								</div>
								<div
									class="smalltitle mt-1 el-text2"
									style="color: inherit"
									v-html="v.ui.content"
								></div>

								<div class="mt-3">
									<div v-for="p in v.select_product_list" class="mb-3">
										<UiProductCol :p="p" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script setup>
import { ref, computed, onMounted, defineProps } from "vue";
import UiProductCol from "./ui/productCol.vue";

import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

import { useL24Store } from "../store/l24";
const store = useL24Store();

const props = defineProps({
	options: {
		type: Object,
	},
});

const liveTitle = ref("지난 라이브");
//data()
const wstore = ref({
	titleFontSize: "24px",
	marginTop: "0",
	marginBottom: "0",
	marginLeft: "0",
	marginRight: "0",
	maxWidth: "100%",
	limit: 10,
});
let init_load = ref(false);

const ToDateFormat = (val, fm) => dayjs(val).format(fm ? fm : "YYYY-MM-DD HH:mm:ss");
const goVod = (data) => {
	window.open(data.link_url, "vod", "width=380,height=700");
};

//methods()
const run = () => {
	init_load.value = true;
};

const setOptions = async (options) => {
	let reload = false;
	try {
		if (options.limit) {
			if (options.limit != wstore.value.limit) {
				reload = true;
			}
		}
	} catch (e) {}
	wstore.value = { ...wstore.value, ...options };

	if (reload) {
		await store.getVodCardList({
			row_count: wstore.value.limit,
		});
	}
};
defineExpose({
	setOptions,
});

//mounted()
onMounted(async () => {
	//계정 환경설정 로드
	await store.getL24Config();

	wstore.value = { ...wstore.value, ...props.options };

	await store.getVodCardList({
		row_count: wstore.value.limit,
	});
	run();
});
</script>

<style scoped>
.l24TraConter {
	container-name: main-container;
	container-type: inline-size;
}

.l24 a {
	text-decoration: none;
	color: #000;
}
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

*,
*:before,
*:after {
	box-sizing: border-box;
	font-family: "Noto Sans KR", sans-serif;
	line-height: 1.5;
	font-size: 14px;
}

.backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 2;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.leftWrap {
	position: relative;
}

.rightWrap {
	padding: 0 20px;
}

.vodr_box {
	background: red;
	color: #fff;
	font-size: 18px;
	letter-spacing: 0;
	font-weight: 600;
	margin-right: 5px;
	display: inline-block;
	line-height: 1.3;
	border-radius: 20px;
	padding: 0.5px 8px 0 8px;
}

.liveTitle {
	font-size: 24px;
	font-weight: 800;
	margin-bottom: 0.1rem;
	letter-spacing: -0.1rem;
	font-family: Noto Sans KR;
	color: #000;
}

.smalltitle {
	letter-spacing: -0.1rem;
	line-height: 1;
	color: #333;
	font-size: 19px;
}

.el-text2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	max-height: 44px;

	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.profile_img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}

.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}

@container main-container (min-width: 728px) {
	.productListWrap {
		height: calc(500px - 20px);
		overflow: hidden;
	}

	.leftWrap {
		position: relative;
		max-width: 460px;
	}
	.thumbBox {
		left: 0;
		top: 0;
		object-fit: cover;
		aspect-ratio: 9/14;
		max-height: 500px;
	}
}

@container main-container (max-width: 728px) {
	.Trawrap {
		justify-content: center;
	}

	.leftWrap {
		position: relative;
	}
	.thumbBox {
		left: 0;
		top: 0;
		object-fit: cover;
		aspect-ratio: 1/1.1;
		max-height: 500px;
	}
}

.w-full {
	width: 100%;
}

.h-full {
	height: 100%;
}

.flex {
	display: flex;
}

.items-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.text-center {
	text-align: center;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.pos_r {
	position: relative;
}
.pos_a {
	position: absolute;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 5px;
	padding-left: 5px;
}

.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-1 {
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}

.col-2 {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.col-5 {
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}

.col-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-7 {
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}

.col-8 {
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}

.col-9 {
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}

.col-11 {
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}

.col-12 {
	flex: 0 0 100%;
	max-width: 100%;
}

@media (min-width: 768px) {
	.col-md {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}

	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}

	.col-md-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}

	.col-md-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}

	.col-md-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-md-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}

	.col-md-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}

	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}

	.col-md-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}

	.col-md-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}

	.col-md-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}

	.col-md-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}

	.col-md-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}

	.col-md-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}
.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}
.ft20 {
	font-size: 20px;
}
.ft40 {
	font-size: 40px;
}
.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.mt-3,
.my-3 {
	margin-top: 1rem !important;
}
.mt-1,
.my-1 {
	margin-top: 0.25rem !important;
}
.overflow-h {
	overflow: hidden;
}
.r20 {
	border-radius: 20px;
}

.mt-2,
.my-2 {
	margin-top: 0.5rem !important;
}
</style>
